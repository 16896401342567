import { Link } from 'react-router-dom';
import './Main.scss';
import MainPic from '../../../assets/img/main-picture.png';

function Main() {
    return (
        <main className="main">
            <div className="container">
                <div className="main__part">
                    <div className="main__about" data-aos="zoom-in" data-aos-offset="300">
                        <h2 className="main__title">Full-Service<br /> Digital Marketing Agency</h2>
                        <p className="main__p">We drive results to grow your business.</p>
                        <Link className="btn-redirect" to={{ pathname: '/packages' }} target={"_blank"}>Get details</Link>
                    </div>

                    <div className="main__img" data-aos="zoom-in-up" data-aos-offset="300">
                        <img src={MainPic} alt="" />
                    </div>
                </div>
            </div>
        </main>
    )
}
export default Main;