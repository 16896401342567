import './Privacy.css'


const Privacy = () => {
    return(
        <div className='Main'>
            <div className='mainCenterWidth'>
                <h1>Elite Globe Solutions Games Privacy Policy</h1>
                <span>This privacy policy explains how we (Elite Globe Solutions) collect and process your personal data when you access and play our Hyper-Casual Games applications and services.</span><br /><br />
                <h2>1. Consent to Policy</h2><br />
                <span>By installing and using the application, you consent to this Privacy Policy and the collection, use 
and sharing of the personal data described in this Policy. If you do not agree, do not install the 
application or use the application.</span><br /><br />
                <h2>2. Scope of Policy</h2><br />
                <span>The scope of this Privacy Policy is limited to the personal data collected by you using our 
applications and services.<br /><br />
This application may contain links to external sites. Clicking on a third-party link will redirect 
you to that site. Please note that we have no control and responsibility for the content and 
Privacy Policies of third-party sites or services.</span><br /><br />
                <h2>3. Information and Data we collect from you</h2><br />
                <span>
                We collect and process the following personal data and information when you using our 
applications and services:
                </span><br /><br />
                <h3>Data automatically collected from applications</h3><br />
                <span>
                your device type and model, manufacturer, operating system platform and version, general 
location (region, country, city, etc), application version, start and end of advertisement browsing,
game play status (stage start, end, and operation information) <br /><br />
IP address and Advertising ID (if you use Apple devices, IDFA / if you use Android devices, 
AAID)
                </span><br /><br />
                <h3>Information you provide when contacting us</h3><br />
                <span>Contact information such as your name and email address, and any other information we need to 
provide support</span><br /><br />
                <h3>
                Data obtained from advertising partners
                </h3><br />
                <span>
                We may obtain personal information about you from third-party advertising partners, depending 
on the permission you give to the partner.
                </span><br /><br />
                <h3>Collecting of special categories of personal data</h3><br />
                <span>We do not knowingly collect any special categories of personal data (personal data revealing 
racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union 
membership, and the processing of genetic data, biometric data for the purpose of uniquely 
identifying a natural person, data concerning health or data concerning a natural person's sex life 
or sexual orientation) or personal data relating to criminal convictions and offences or related 
security measures when providing applications and services.</span><br /><br />
                <h2>4. Purpose and Legal basis for use of information</h2><br />
                <h3>4.1 How we use your information</h3><br />
                <span>We collect and use your data and information for the following purposes.</span><br /><br />
                <h3>Provision and improvement of game services</h3><br />
                <span>We use your personal data to distribute applications and provide gaming services, and to 
improve our services. <br /><br />
In order to properly provide game services, it is necessary to collect, record and use information 
such as game progress.<br /><br />
We use your information to analyze your personal data and to correct game glitches, improve 
stability, and develop new services to provide you with a better experience.<br /><br />
We also use the information when sending notifications, including app updates, support and 
management messages.
</span><br /><br />
                <h3>Security</h3> <br />
                <span>
                We use your information to enhance the security and security of our services. <br /> <br />
We use the information to monitor suspicious and unexpected use, keep our services secure and 
stable, and send you notifications, such as security alerts. <br /> <br />
We also use your information to identify and prevent fraud in advertising campaigns.
                </span> <br /> <br />
                <h3>Advertising display and promotion</h3> <br />
                <span>
                We use your information to display ads for our games in applications released by other providers
and to display third-party advertisements in our games. While you are playing our games, we 
may display advertisements for our other games. <br /> <br />
We use your personal data to understand your preferences and display better ads. <br /> <br />
We also use your personal data to contact you about promotions, events and other news about the
services we or our partners offer.
                </span> <br /> <br />
                <h3>Providing customer support</h3> <br />
                <span>We use your personal data to respond to your inquiries and provide customer support.</span> <br /> <br />
                <h3>Legal obligation</h3> <br />
                <span>We use your information where necessary to comply with our legal obligations.</span> <br /> <br />
                <h3>4.2 Legal Basis</h3> <br />
                <span>
                We collect and use personal data of users subject to GDPR or the equivalents on the following 
legal basis: <br /> <br />
(1) Performance of contractual obligations <br /> <br />
You must use your information to fulfill your obligations under your contract (to provide the 
services you request, improve our services, and provide customer support). <br /> <br />
(2) Consent <br /> <br />
You have provided us with your consent to use personal data and information for specific 
purposes. <br /> <br />
This applies to information displayed within the application that is personalized to your 
preferences and to which information is shared with advertising partners for that purpose. This 
consent can be withdrawn at any time. <br /> <br />
(3) Legal obligations <br /> <br />
We collect and process your personal data where necessary to comply with the legal obligations 
we follow. <br /> <br />
(4) legitimate interests <br /> <br />
If (1) or (2) or (3) does not apply, we need to use your personal data for our legitimate interests 
or for legitimate interests pursued by third parties. <br /> <br />
We use your personal data to analyze, track and prevent fraud, notify, cross-promote and 
advertise to provide you with free or low-cost services, improve our services and improve your 
data Special attention can be paid to the right to protection.
                </span><br /> <br />
                <h2>5. Sharing your Information</h2> <br />
                <span>
                We may share the your information with partners for the purposes described above. <br /> <br />
The privacy policies of each partner are listed in the "Partner List" section. <br /> <br />
We may disclose your information in response to law, legal process, lawsuits, and the demands 
of public and governmental authorities inside and outside your country of residence. <br /> <br />
We may disclose your information to public authorities if we believe necessary to protect and 
challenge the legal rights of us, our customers and partners, and to exercise our legal rights in 
accordance with relevant laws. <br /> <br />
We will not notify you of such disclosures unless required by law.
                </span><br />  <br />
                <h3>Partner list</h3> <br />
                <span>We may share your information with the following partners to improve our app and provide 
more tailored advertising. <br /> <br />
We may receive your personal data from advertising partners.</span> <br /> <br />
                <ul style={{"listStyle": "disc"}}>
                    <li>Google : https://policies.google.com/privacy</li>
                    <li>Firebase : https://firebase.google.com/support/privacy</li>
                    <li>Unity : https://unity3d.com/legal/privacy-policy</li>
                    <li>Facebook : https://www.facebook.com/privacy/explanation</li>
                </ul> <br /> 
                <h2>6. Security of personal information</h2> <br />
                <span>
                In order to prevent the loss, destruction, falsification, damage, leakage, etc. of personal 
information, we will introduce appropriate and reasonable level of security measures to protect 
personal information. When outsourcing the handling of personal information, we strongly urge 
you to comply with applicable personal information protection laws and work to maintain the 
confidentiality of information. <br /> <br />
We always make every effort to protect your personal information, but we do not guarantee or 
represent that your information is fully protected.

                </span> <br /> <br />
                <h2>7. Rights of users under GDPR or the equivalents</h2> <br />
                <span>
                If you are under GDPR or the equivalents, you have the following rights regarding the 
processing of personal data: <br /> <br />
When you exercise the following rights, you can send a request to the contacts listed in the 
"Contacts" section. <br /> <br />
We may ask you to provide certain information in order to identify and correct your data.
                </span> <br /> <br />
                <h3>Right to withdraw consent</h3> <br />
                <span>
                You have the right to withdraw your consent at any time in accordance with Article 7(3), 
paragraph 3 of the GDPR. <br /> <br />
If you wish to withdraw your consent, please contact us at the address specified in this policy to 
withdraw your consent. The withdrawal of this consent cannot affect the legality of the treatment
based on the consent before the withdrawal. <br /> <br />
If you withdraw your consent, you will not be able to continue using the applications and 
services.

                </span> <br /> <br />
                <h3>Right of access and rectification</h3> <br />
                <span>
                You have the right to receive confirmation as to whether or not personal data concerning him or 
her are being processed, and, where that is the case, access to the personal data in accordance 
with Article 15 of the GDPR. <br /> <br />
You also have the right to obtain from us without undue delay the rectification of inaccurate 
personal data concerning you in accordance with Article 16 of the GDPR.
                </span> <br /> <br />
                <h3>Right of erasure and restriction of processing</h3> <br />
                <span>
                In accordance with Article 17 of the GDPR, you shall have the right to obtain from us the erasure
of personal data concerning you without undue delay. <br /> <br />
You also have the right to restrict the handling of personal data in accordance with Article 18 of 
the GDPR. <br /> <br />
However, if your personal data is legitimately used to provide our services and for other 
purposes, we may reject your request or ask you to postpone the response.
                </span> <br /> <br />
                <h3>Right to data portability</h3> <br />
                <span>
                In accordance with Article 20 of the GDPR, you shall have the right to receive the personal data 
which you have provided to us, in a structured, commonly used and machine-readable format 
and have the right to transmit those data to another controller without hindrance from us to which
the personal data have been provided. <br /> <br />
And you have the right to have the personal data transmitted directly from us to another, where 
technically feasible.
                </span> <br /> <br />
                <h3>Right to object</h3> <br />
                <span>
                in accordance with Article 21 of the GDPR, you shall have the right to object, on grounds 
relating to your particular situation, at any time to processing of personal data concerning you.
                </span> <br /> <br />
                <h3>Right to lodge a complaint with a supervisory authority</h3> <br />
                <span>In accordance with Article 77 of the GDPR, without prejudice to any other administrative or 
judicial remedy, you shall have the right to lodge a complaint with a supervisory authority, in 
particular in the Member State of his or her habitual residence, place of work or place of the 
alleged infringment if you considers that the processing of personal data relating to you infringes
this Regulation.</span> <br /> <br />
                <h3>Transferring of your personal data</h3> <br />
                <span>We may transfer and process your personal data from your country or region of residence to 
Japan and other countries around the world. We may share your information with international 
organizations as partner. Please note that these countries and jurisdictions may not have the same
data protection laws as your country.</span> <br /> <br />
                <h2>8. Data retention period</h2> <br />
                <span>We will retain your information for as long as necessary to provide the service, unless required 
by law or unless we accept your request to delete the information. <br /> <br />
We will retain and use your information for as long as we need it to comply with our legal 
obligations.4</span> <br /> <br />
                <h2>9. Children's Personal Information Policy</h2> <br /> 
                <span>
                This application and service are not available to users under 16 years of age. <br /> <br />
However, we cannot determine if the personal information sent is for persons under 16 years of 
age. We do not knowingly collect personal information from anyone under the age of 16.
                </span> <br /> <br />
                <h2>10. Contact</h2> <br />
                <h3>Controller</h3> <br />
                <span>Elite Globe Solutions</span> <br /> <br /> <br /> <br />
                <h3>Contact information</h3> <br /> <br />
                <span>Elite Globe Solutions <br /> <br />
Address: 6402 Ben Ave apt 102 <br /> <br />
North Hollywood, CA 91606 USA  <br /> <br />
Email: info@eliteglobesolutions.com</span> <br /> <br />
                <h2>11. Change of Privacy Policy</h2> <br />
                <span>
We may update this Privacy Policy from time to time as necessary</span>
             </div>
        </div>  
    )
}


export default Privacy