import { Helmet } from "react-helmet";
import { useContext, useEffect } from "react";
import './BlogPage.scss';
import { BsCalendar2Week } from 'react-icons/bs';
import BlogPic1 from '../../../../assets/img/BlogPic4.jpg';
import BlogPic2 from '../../../../assets/img/BlogPic2.jpg';
import BlogPic3 from '../../../../assets/img/BlogPic3.jpg';
import BlogPic4 from '../../../../assets/img/BlogPic1.jpg';
import { MdOutlineBookmarks } from 'react-icons/md';
import BlogComment from '../blogComment/BlogComment';
import { Link } from 'react-router-dom';
import idGenerator from '../../../idGenerator'
import {Context} from '../../../../index'
import {sanitize} from 'dompurify'
function BlogPage(props) {
    const {store,db} = useContext(Context)
    const data = store.getState().blog

    let nextPrevIds = []
    for (let i = 0; i < data.length; i++) {
        if (data[i].id === props.location.state.id) {
            nextPrevIds = []
            if (i === 0) {
                nextPrevIds.push(null)
                nextPrevIds.push(data[i + 1])
            } else if (i === data.length - 1) {
                nextPrevIds.push(data[i - 1])
                nextPrevIds.push(null)
            } else {
                nextPrevIds.push(data[i - 1])
                nextPrevIds.push(data[i + 1])

            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.location.state.title]);


    return (
        <>
            <Helmet>
                <title>{props.location.state.title} - Eliteglobesolutions</title>
                <link rel="canonical" href="http://eliteglobesolutions.com/blog/blog-page" />
            </Helmet>
            <div className="blogPage">
                <div className="container">
                    <div className="blogPage__main">
                        {data.filter(item => item.id === props.location.state.id).map(item => (
                            <div key={item.id} className="blogPage__post">
                                <div className="blogPage-img" data-aos="zoom-in" data-aos-offset="300">
                                    <img src={item.img} alt="Blog-Img" />
                                </div>
                                <div className="blogPage__center" data-aos="zoom-in" data-aos-offset="300">
                                    <h6>{item.name}</h6>
                                    
                                    <span><BsCalendar2Week /> {item.date}</span>
                                    <h2>{item.title}</h2>                   
                                    <br />
                                    <p dangerouslySetInnerHTML={{__html:sanitize(item.text1)}}></p>
                                    <br />
                                    <p dangerouslySetInnerHTML={{__html:sanitize(item.text2)}}></p>
                                    <br />
                                    <h4>{item.about}</h4>
                                    <br />
                                    <p dangerouslySetInnerHTML={{__html:sanitize(item.text3)}}></p>
                                    <br />
                                    <p dangerouslySetInnerHTML={{__html:sanitize(item.text4)}}></p>
                                    <br />
                                    <p dangerouslySetInnerHTML={{__html:sanitize(item.text5)}}></p>
                                    {item.html 
                                        ?
                                            <div className="blogPage__post" dangerouslySetInnerHTML={{__html:sanitize(item.html)}}>

                                            </div>
                                        :
                                            null
                                    }
                                    <div className="blogPage-tags">
                                        <MdOutlineBookmarks className="blogPage-tags-mark" />
                                        {item.tag1 
                                        ?
                                        <button className="blogPage-tags-btn">{item.tag1}</button>
                                        : 
                                        <button className="blogPage-tags-btn">Business</button>
                                        }
                                        {item.tag2
                                        ?
                                        <button className="blogPage-tags-btn">{item.tag2}</button>
                                        : 
                                        <button className="blogPage-tags-btn">IT Security</button>
                                        }
                                        {item.tag3
                                        ?
                                        <button className="blogPage-tags-btn">{item.tag3}</button>
                                        : 
                                        <button className="blogPage-tags-btn">IT Services</button>
                                        }
                                        {item.tag4
                                        ?
                                        <button className="blogPage-tags-btn">{item.tag4}</button>
                                        : 
                                        <button className="blogPage-tags-btn">Marketing</button>
                                        }
                                        {item.tag5
                                        ?
                                        <button className="blogPage-tags-btn">{item.tag5}</button>
                                        : 
                                        <button className="blogPage-tags-btn">Smm</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                    </div>

                    <div className="next-prevpart">
                        <div className="prevNext">
                            {nextPrevIds.filter(item => nextPrevIds.indexOf(item) === 0 && item !== null).map(item => (
                                <div kay={item.key} className="prev">
                                    <Link to={{
                                        pathname: item.link,
                                        state: {
                                            id: item.id,
                                            title: item.title
                                        }
                                    }} > <div className="img">
                                            <img src={item.img} alt="" />
                                        </div></Link>

                                </div>
                            ))
                            }

                        </div>
                        <div className="prevNext">
                            {nextPrevIds.filter(item => nextPrevIds.indexOf(item) === 1 && item !== null).map(item => (
                                <div kay={item.id} className="next">
                                    <Link to={{
                                        pathname: item.link,
                                        state: {
                                            id: item.id,
                                            title: item.title
                                        }
                                    }}><div className="img">
                                            <img src={item.img} alt="" />
                                        </div></Link>
                                </div>
                            ))
                            }
                        </div>
                    </div>

                    <BlogComment />

                </div>
            </div>
        </>
    )
}

export default BlogPage
