import { Link } from 'react-router-dom';
import './LowArticle.scss';
import { BsTelephoneOutbound } from 'react-icons/bs';

function LowArticle() {
    return (
        <div className="lowArticle">
            <div className="container">
                <div className="lowArticle__row">
                    <p>
                        We’re available 8 hours per day!<br />
                        Contact to require a detailed analysis and assessment of your plan.
                    </p>

                    <div className="lowArticle__col">
                        <BsTelephoneOutbound className="tel-icon" />
                        <h6>Reach out now!</h6>
                        <a className="lowArticle__col-a" href="tel:++1 (818) 878-8081">+1 (818) 878-8081</ a>
                        <Link to={{ pathname: '/contact-us'}} target={"_blank"} className="btn-redirect">Contact Us</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LowArticle;