import { Helmet } from "react-helmet";
import './FAQs.scss';
import HighArticle from '../../highArticle/HighArticle';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';
import { BsChevronDown } from 'react-icons/bs';

function FAQs() {
    return (
        <div>
            <Helmet>
                <title>Local SEO | E-Commerce Platform Development</title>
                <link rel="canonical" href="http://eliteglobesolutions.com/faqs" />
                <meta name="description" content="Mobile and Desktop Digital Marketing Agency" />
            </Helmet>
            <HighArticle name='FAQs' />

            <div className="faqs">
                <div className="container">
                    <h1 className="sec_title" data-aos="zoom-in" data-aos-offset="300">Frequently Asked Questions that our clients usually ask us</h1>
                    <div className="faqs__accordion-card">
                        <Accordion className="faqs__accordion">

                            <AccordionItem className="faqs__accordion-item">
                                <AccordionItemHeading>
                                    <AccordionItemButton className="faqs__accordion-btn is-open">
                                        <p>WHAT ARE YOU SPECIALIZED IN?</p> <BsChevronDown className="faqs__accordion-chevron" />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="faqs__accordion-panel">
                                    <p>
                                        We are a provider of end-to-end marketing services narrowed down in business expansion. Holdings represent the overall technical and quick-witted human resources carrying out reckons on web design, SEO, branding, social media, PPC, SEM supplementary.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem className="faqs__accordion-item">
                                <AccordionItemHeading>
                                    <AccordionItemButton className="faqs__accordion-btn">
                                        <p>HOW LONG HAVE YOU BEEN INTRODUCING YOUR SERVICES?</p> <BsChevronDown className="faqs__accordion-chevron" />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="faqs__accordion-panel">
                                    <p>
                                        We have become an inseparable part of the industry since 2007. Newly generating companies have come and gone contributing irreplaceable competitive advantages.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem className="faqs__accordion-item">
                                <AccordionItemHeading>
                                    <AccordionItemButton className="faqs__accordion-btn">
                                        <p>WHAT IS THE GEOGRAPHIC OF YOUR OPERATIONS?</p> <BsChevronDown className="faqs__accordion-chevron" />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="faqs__accordion-panel">
                                    <p>
                                        Ours is LA-based company sustaining intercontinental distant disclosure enabling to fulfill schemes, ventures via E-contacts (counting chats, phone calls, video imparting).
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem className="faqs__accordion-item">
                                <AccordionItemHeading>
                                    <AccordionItemButton className="faqs__accordion-btn">
                                        <p>WHAT KIND OF CLIENTS DO YOU USUALLY WORK WITH?</p> <BsChevronDown className="faqs__accordion-chevron" />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="faqs__accordion-panel">
                                    <p>
                                        We service over 20 industries including retail, e-commerce, entertainment, real estate, media, legal, home improvement, automotive, financial, services, beauty, fashion, and more. We provide every marketing aspect for a successful online presence. But, we haven’t come across industry we could not master.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>

                        <Accordion className="faqs__accordion">

                            <AccordionItem className="faqs__accordion-item">
                                <AccordionItemHeading>
                                    <AccordionItemButton className="faqs__accordion-btn is-open">
                                        <p>WHAT IS THE MAIN SECTOR THAT YOU ARE SERVING?</p> <BsChevronDown className="faqs__accordion-chevron" />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="faqs__accordion-panel">
                                    <p>
                                        Setting out multi-dimensional domains we cover e-commerce heedless of financial services, beauty, fashion, electronics, toys, sports, gardening, home decoration multitudinous quarters warranting the fruitful presence of machine-accessible, non-machine-accessible environments. No trade or corporation escapes handling.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem className="faqs__accordion-item">
                                <AccordionItemHeading>
                                    <AccordionItemButton className="faqs__accordion-btn">
                                        <p>ARE WEB HOSTING SERVICES AVAILABLE WITH YOU?</p> <BsChevronDown className="faqs__accordion-chevron" />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="faqs__accordion-panel">
                                    <p>
                                        Purchasers persistently keep the track of ours to receive updates, future-needed management, into the bargain we superintend hundreds of websites, pages, servers.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem className="faqs__accordion-item">
                                <AccordionItemHeading>
                                    <AccordionItemButton className="faqs__accordion-btn">
                                        <p>THE IMPLEMENTATION OF SPECIAL MARKETING TOOLS IS COMMON WITH YOU?</p> <BsChevronDown className="faqs__accordion-chevron" />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="faqs__accordion-panel">
                                    <p>
                                        Appraisal of triumphal tops occurs resultantly with the introduction of reputation scale assessment. Analytics auspiciously enhances ROI based tools enlightening hidden horizons of possibilities bringing the edges together.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem className="faqs__accordion-item">
                                <AccordionItemHeading>
                                    <AccordionItemButton className="faqs__accordion-btn">
                                        <p>DO YOU POSSESS GOOGLE ADWORDS CERTIFICATE?</p> <BsChevronDown className="faqs__accordion-chevron" />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="faqs__accordion-panel">
                                    <p>
                                        The level of expertise is not based on individual appraisals but appropriate certificates indicating the professionalism timed by expertise.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQs;