import {useContext, useState} from 'react'
import { doc,updateDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import {Context} from '../../index'
import './Edit.css'

const Edit = (props) => {
    const {db,storage} = useContext(Context) 
    const [paragraph,setParagraph] = useState(props.data.filter(item => item.id === props.id).map(item => item.paragraph))
    const [name,setName] = useState(props.data.filter(item => item.id === props.id).map(item => item.name))
    const [image,setImage] = useState(null)
    const [image2,setImage2] = useState([])
    const [imageChanged,setImageChanged] = useState(false)
    const [imageUrl,setImageUrl] = useState(null)
    const [imageUrl2,setImageUrl2] = useState(null)
    const [imageUploaded1,setImageUploaded1] = useState(false)
    const [imageUploaded2,setImageUploaded2] = useState(false)
    const [category,setCategory] = useState(props.data.filter(item => item.id === props.id).map(item => item.type))
    const [url,setUrl] = useState(props.data.filter(item => item.id === props.id).map(item => item.path))
    const [main,setMain] = useState(props.data.filter(item => item.id === props.id).map(item => item.mainTitle))
    const [paragraphChanged,setParagraphChanged] = useState(false)
    const [nameChanged,setNameChanged] = useState(false)
    const [categoryChanged,setCategoryChanged] = useState(false)
    const [urlChanged,setUrlChanged] = useState(false)
    const [mainChanged,setMainChanged] = useState(false)
    console.log(props)

    const saveEdit = () => {
        
        if (image) {
            const storageRef = ref(storage, '/images/' + image.name)
            uploadBytes(storageRef, image).then(response => {
                console.log('file1 uploaded')
            })
            getDownloadURL(storageRef,image.name)
                .then(url => {
                    setImageUrl(url)
                    console.log('file 1 url -' + imageUrl)
                })
        }
        if (image2) {
            const storageRef2 = ref(storage, '/images/' + image2.name)
            uploadBytes(storageRef2, image).then(response => {
                console.log('file1 uploaded')
            })
            getDownloadURL(storageRef2,image2.name)
                .then(url => {
                    setImageUrl(url)
                    console.log('file 1 url -' + imageUrl2)
                })
        }


        if(paragraph !== '' && paragraphChanged === true){
            updateDoc(doc(db,'messages', props.id), {
                paragraph: paragraph
            })
        }
        if(main !== '' && mainChanged === true){
            updateDoc(doc(db,'messages', props.id), {
                mainTitle: main
            })
        }
        if(name !== '' && nameChanged === true ){
            updateDoc(doc(db,'messages', props.id), {
                name: name,
            })
        }
        if(imageUrl !== null){
            updateDoc(doc(db,'messages', props.id), {
                img:imageUrl,
            })
            setImageUploaded1(true)
        }else{
            setImageUploaded1(false)
        }

        if(imageUrl2 !== null  && imageChanged === true){
            updateDoc(doc(db,'messages', props.id), {
                image2:imageUrl2
            })
            setImageUploaded2(true)
        }else{
            setImageUploaded2(false)
        }

        console.log(props.id, 'edited')
        if(category != '' && categoryChanged === true){
            updateDoc(doc(db,'messages', props.id), {
                type:category
            })
        }
        if(url !== '' && urlChanged === true){
            updateDoc(doc(db,'messages', props.id), {
                path:url
            })
        }

    }
    console.log(imageUrl2)


    return (
        <div className=''>
            <div className='flex_column'>
                <input value={url} className='edit_input' placeholder='Edit URL' onChange={(e) => {
                    setUrl(e.target.value)
                    setUrlChanged(true)
                }} />
                <input value={main} className='edit_input' placeholder='Edit main text' onChange={(e) => {
                    setMain(e.target.value)
                    setMainChanged(true)
                }} />
                <input value={name} className='edit_input' placeholder='Edit Title' onChange={(e) => {
                    setName(e.target.value)
                    setNameChanged(true)
                }} />
                <textarea value={paragraph} placeholder='Edit Description' className='second_input' onChange={(e) => {
                    setParagraph(e.target.value)
                    setParagraphChanged(true)
                }} rows={"5"} cols="67" ></textarea>
                <label className='color_white'>Main Picture</label>
                <input type={'file'} onChange={(e) => setImage(e.target.files[0])} />
                {imageUploaded1 
                ?
                    <span className='image_uploaded_text'>Image succsesfully uploaded</span> 
                :   
                    <span className='image_dont_uploaded'>Try again image dont uploaded</span>
                }
                <label className='color_white'>Article pictures (1200 x 800)</label>
                <input type={'file'} onChange={(e) => setImage2(e.target.files[0])} />
                
                {imageUploaded2 
                ?
                    <span className='image_uploaded_text'>Image succsesfully uploaded</span> 
                :   
                    <span className='image_dont_uploaded'>Try again image dont uploaded</span>
                }
                <div className='select_category'>
                    <select className='' value={category} onChange={e => {
                        setCategory(e.target.value)
                        setCategoryChanged(true)
                    }}>
                        <option value={'web'} onChange={e => setCategory(e.target.value)}>Web</option>
                        <option value={'seo'} onChange={e => setCategory(e.target.value)}>Seo</option>
                    </select>
                </div>
                <button className='saveButton' onClick={saveEdit}>Save</button>
            </div>
        </div>
    )
}


export default Edit