import { Helmet } from "react-helmet";
import './portfolioPages/PortfolioPages.scss';
import { collection,getDocs } from 'firebase/firestore'
import { useContext, useEffect, useState } from "react";
import { sanitize } from 'dompurify'
import { Context } from "../../../index";
const PortfolioId = (props) => {
    const {store} = useContext(Context)
    const pageId = props.match.params.id
    const portfolioData = store.getState().portfolio.data2
    console.log(portfolioData)


    return (
        <>
            {portfolioData.filter(id => id.path === pageId).map(item => 
                <>
                                <Helmet>
                <title>{item.name} - Eliteglobesolutions</title>
                <link rel="canonical" href="http://eliteglobesolutions.com/portfolio/a-to-z-hvac" />
                <meta name="description" content={`${item.name} - Eliteglobesolutions`} />
            </Helmet>
            <div className="portfolioPage">
                <div className="container">
                    <div className="portfolioPageSec">
                        <div className="portTextPart" data-aos="zoom-in" data-aos-offset="300">
                            <div className="TextPart">
                                <h3>
                                    {item.name}
                                </h3>
                                <p dangerouslySetInnerHTML={{__html: sanitize(item.paragraph)}}></p>
                            </div>
                        </div>
                        {item.video 
                        ?
                        <div className="portImagePart aos-init aos-animate">
                            <iframe width="500" height="500" src={item.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        :
                        <div className="portImagePart" data-aos="zoom-in" data-aos-offset="300">
                            <img src={item.image2} alt="" />
                        </div>
                        }

                    </div>
                </div>
            </div>
                </>
                
                )}

        </>
    )
}


export default PortfolioId