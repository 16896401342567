import './SliderMarketing.scss';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { Link } from 'react-router-dom';
import pt1 from '../../../assets/img/pure-min.png';
import pt2 from '../../../assets/img/pro-min.png';
import pt3 from '../../../assets/img/hayq-min.png';
import pt4 from '../../../assets/img/dfp-min.png';
import pt5 from '../../../assets/img/hayq-games.png';

const data = [
    {
        id: 1,
        img: pt1,
        link: "https://purelifefoundation.us/"
    }, 
    {
        id: 3,
        img: pt3,
        link: "https://hayqinc.com/"

    },  
    {
        id: 5,
        img: pt5,
        link: "https://hayqgames.com/"
    },
    {
        id: 2,
        img: pt2,
        link: "https://proreca.am/"
    },
   
    {
        id: 4,
        img: pt4,
        link: "http://dfpexpert.com/"

    },
  
]

SwiperCore.use([Autoplay]);

function SliderPartners() {
    return (
        <section className="sliderPartners">
            <div className="container">
                <h2 className="sec_title other_title">Our Partners</h2>
                <Swiper
                    spaceBetween={30}
                    slidesPerView={5}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    autoplay={{
                        delay: 3000,
                    }}

                    breakpoints={{
                        "250": {
                            "slidesPerView": 2,
                        },
                        "500": {
                            "slidesPerView": 3,
                        },
                        "860": {
                            "slidesPerView": 4,
                        },
                        "1100": {
                            "slidesPerView": 4,
                        }
                    }}
                >
                    {data.map(slide => (
                        <SwiperSlide
                            key={slide.id}
                        >
                            <Link to={{ pathname: slide.link }} target="_blank" className="single_slide">
                                <div className="slide_img">
                                    <img src={slide.img} alt="" />
                                </div>
                            </Link>

                        </SwiperSlide>

                    ))}
                </Swiper>
            </div>
        </section>

    )
}

export default SliderPartners
