import { Helmet } from "react-helmet";
import { useEffect } from "react";
import CareerSeo from '../../../../assets/img/careerSeo.png';
import CareerWordpress from '../../../../assets/img/careerWordpress.png';
import './CareersPage.scss'

function CareersPage(props) {

    const data = [
        {
            id: '1c1',
            title: 'SEO Manager',
            job_description: "Development and application of SEO strategies to allow the digital marketing team to conduct efficient promotions, as well as to improve the website, social media and other content activities of the firm.Content coordination, architecture, social media, communications (PPC) and other practices. Content coordination. Projects and results reporting management on site and on-site optimization. Analysis of continuous keywords like the search and expansion of keywords. To conduct research and enforce content guidelines on organic SEO results.",
            job_overview: "We are looking for an SEO Manager to help us organize our on-site and off-site optimization and keyword research for SEO operations. You will also ensure that paid search campaigns are effective. We seek an SEO Manager who can communicate effectively and cooperate with different departments, with analysis and perception. Awareness in SEO approaches and techniques is significant. Your job is to ensure that search engine content is tailored for the benefit of our business.",
            img_path: CareerSeo,
            job_responsibilities: [
                "• Continuing the development, promotion of the material, outreach and earning connect",
                "• Collaboration with creators, the digital marketing team to develop linkback initiative activities including videos and linkable assets",
                '• Assistance in all SEO activities including analysis on keywords and audits',
                "• Crawling and logistical problems monitoring sites",
                "• Efficient clone of SEO for online accounts and social media",
                "• Places for enhancement and optimisation are reviewed and analyzed",
                "• Development of Online Rating Optimization Techniques",
                "• Write a mighty guest call to action",
                "• Study of competitors, research of market and current algorithm SEO best practices, and UX/UI updates",
                "• Development and implementation of the Basic SEO Guideline",
                "• Setup and management of keyword targeted for optimized content strategies",
                "• Capable of delivering information-driven feedback for changes to SEO by Google Analytics",
                "• Defining, measuring and evaluating SEO efforts effectiveness.",
                "• Continue to work expertise of new developments in the areas of SEO, UI/UX, Google upgrade algorithms...",
                "• SEO audits can be carried out with the help of technologies and direct evaluation of SEO best practices (Google Ads and Bing Ads)",
                "• Set measurable marketing activities objectives",
                "• Track monthly and annual success measurements to recognize SEO performance",
                "• Manage SEO resources and channels including Google My Company, Google Search Console, Google Analytics and SEMrush ",
                "• SEO Approach for both short and long term progress",
                "• Regular on-site and off-site tracking of SEO variables that impact on skilled lead generation over tactics",
                "• Maximization and searching results of content and website associations ",
                "• Maintenance of online credibility ",
                "• Report and analyze using SEO software and keep abreast of and implement strongly the highest technology;  ",
                "• Create and manage SEO project timing and outcomes (keyword rating, local rankings, ORM cleanup) SEO manager requirements",
            ],
            job_requirements: [
                "• Bachelor’s degree in Management or Marketing",
                "• Search Engine Optimization specialist with a further 2 years of experience.",
                "• Strong understanding of the search engine (SEM) marketing and search engine optimization(SEO) process (Google, WordTracker, Keyword Discovery, etc)",
                "• Multi CMS content development and management experience  CMS experience.",
                "• PowerPoint, G Suite, Microsoft Office, and MacOS high-level expertise.",
                "• Web analytics experiment using a range of analytical methods and internal reporting tools",
                "• Knowledge of PPC systems and data optimisation from paid and non-organic outlets. Google Analytics and AdWords preferred credential  ",
                "• Comprehensive experience with Google, Yahoo and Bing analysis platforms (Google Analytics, SEMrush, Ahrefs, Google Search Console, Google Adwords, Screaming Frog, etc.)",
                "• Solid understanding of value promotion, transformation and on-line user acquisition",
                "• HTML, CSS, and JavaScript creation and constraints work experience ",
                "• Google My Business optimization and management experience",
                "• Knowledge of recent SEO developments, best practices and changes",
                "⇩",
                "To apply, please send your resume to careers –info[@]eliteglobesolutions.com",
            ]
        },
        {
            id: '2c2',
            title: 'WordPress Developer',
            job_description: 'Developers of WordPress implement tools for designing and running company websites. The development at both the front and back end, as well as the introduction of theme and plugin the high merits of the sphere and deeper knowledge is more than mandatory. The aim is to create websites satisfying the needs of potential customers.',
            job_overview: "Our team is welcoming those with the below-given skills. Our rising talented team is in search of a multi-functional, knowledgeable developer to help us keep a nice and accessible website that meets the specific criteria of our web's outer appearance and functionality. The vacancy holder is carrying the layout of both backend and front ends, the theme and installation of plugins, website integration, and handling the processes with security upgrades as well as prohibiting possible security matters.The comprehensive skills of front-end programming languages. The accomplishment of the goals set for the specified vacancy.",
            img_path: CareerWordpress,
            job_responsibilities: [
                "• New features often implied in the workshop",
                "• Maintenance of the website infrastructure",
                "• Managing and maintaining quality efficiency and availability in all technical areas of the CMS",
                "• Assisting in the development, transforming, and converting of a functional blueprint and plug -in ",
                "• Improving website speed, reliability, and user interface",
                "• Responding to and resolving website concerns",
                "• Developing plans to boost subscriptions and visitors to our site",
                "• Keep an eye on the success of the Live website.",
                "• Additional administrative functions",
                "• Control WordPress domain topics ",
                "• New architecture",
                "• Know - how of keyword identifying",
                "• Checking the efficiency of strategies",
            ],
            job_requirements: [
                "• Graduated from a four - year university or computer science or a corresponding profession",
                "• Three to five years of similar practice and / or preparation or an equal mix of education and capabilities ",
                "• Competencies in project management",
                "• Strong written and interpersonal communications ability",
                "• Comprehensive design layout and esthetics",
                "• Comprehensive awareness of programming technologies",
                "• Experience with the development team",
                "⇩",
                "To apply, please send your resume to careers –info[@]eliteglobesolutions.com",
            ]
        },
        {
            id: '3c3',
            title: 'Database Administrator',
            job_description: "Database administrators ensure smooth operation of applications. The DB Managers archive and organize the use of specialized software documents, such as financial data and customer delivery information. It ensures that the data are available to consumers and is safeguarded against unauthorized access.",
            job_overview: "We're looking to join our squad for a Database Administrator. The testing and implementation of electronic databases are supposed to be carried out by DB administrator. Assessment in scheduling for migrating from previous product versions, including initialization, as well as the creation of new application versions are among the significant obligations and responsibilities. Backup and recovery plans and procedures must be developed by DBAs based on industry best practices to ensure every move.A DBA shall be conscious of the potential failings and dangers of the database program and of the company's overall system. Check who is allowed to login and what kind of access is not forbidden according to the overall regulations. Continuous DBA system management entails the tracking of performance issues in data bases. DBA is responsible for troubleshooting in the event of some problem.",
            img_path: CareerSeo,
            job_responsibilities: [
                "• Coordination of modification of computer databases",
                "• Implementation of security mechanisms to secure computer databases",
                "• Development and management of database systems, tables, processes and permissions",
                "• Computer databases management",
                "• Repeat and restore corrupt tables or databases",
                "• Implement authentication database",
                "• Execution of mirrouring database, backup, failover, duplication and catastrophe recovery activities",
                "• Perform upgrades and updates",
                "• Creating scripts for process automation",
                "• Installing modern database technology proactively, testing, evaluating and recommending new ones",
                "• Communicating and collaborating frequently with workers to ensure data bases are integral, security and optimized",
                "• Capability and performance criteria analysis and maintenance",
                "• Clearly and efficiently report on the development of the programs delegated",
                "• Working with senior professional resources (architects, Senior DBAs) on the architecture of documents and design of existing and new applications.",
                "• Exercise discretion and critical judgments on important issues",
                "• Identify customer interviewing criteria for databases; analyze application, programming and operational departments; evaluate current programs and develop suggested systems",
                "• Maintaining database output by optimal calculation of data base parameters; new releases are implemented; maintenance specifications are complete; computing systems and hardware components are evaluated.",
                "• Write and maintain database documents with data requirements and protocols, as well as data dictionary descriptions(metadata)",
                "• Backup and Recovery Plans Develop, Maintain and Test database access and permissions",
                "• Commission and install new software and customize current applications to make them suitable for the purpose • Maintaining quality of operation by organizational practices and compliance.",
                "• Execute professional and technical expertise by engaging in training workshops; review professional publications; build personal networks; benchmark state-of-the-art activities.",
                "• Developing and maintaining customized SSRS reports on data from different applications.",
            ],
            job_requirements: [
                "• Bachelor's degree in computer science,",
                "• Must be completely knowledgeable about the 2005/2008 R2, 2012/2006 Microsoft SQL Server models",
                "• Must be experienced in Windows Server Management 2008/2012/2019",
                "• Scripting experience is mandatory (T-SQLs, PowerShell, database scripting, etc.)",
                "• Microsoft SQL Server Database Management Certifications were an advantage",
                "• Experience in cloud-based optimization is an advantage.",
                "• Excellent writing and verbal speaking abilities in English",
                "• Learn fast in dealing with new problems; a multi-faceted student who is sensitive to change;",
                "• Know-how in critical thought for recognizing trends and data irregularities and drawing new conclusions",
                "• Rapid, result-focused work; targets should be successfully exceeded",
                "• Effective communication skills, both written and oral, in order to convey nuanced results and ideas into the rational, easy to understand and practical language;",
                "• Contradictory mission capability and priority",
                "• Team player with a willingness and capacity to work with the workers efficiently",
                "⇩",
                "To apply, please send your resume to careers –info[@]eliteglobesolutions.com",
            ]
        },
        {
            id: '4c4',
            title: 'Content Writer',
            job_description: " Content Writers are responsible for creating text content that is engaging, informative and persuasive. They need to be able to take a broad concept or idea and write about it in an interesting way. The best Content Writers have strong writing skills as well as excellent knowledge of the subject matter they are covering.",
            job_overview: "Our editing team is highly interested in involving a content writer for producing new blog posts. We are searching for a content writer that will further enrich the marketing backups of our blogs and revision papers once they are posted.      Content Writer shall undertake comprehensive industry-related analysis, generate ideas for new content styles and review the papers before publishing. If you know how to make web content and are attentive to accuracy, we would like to meet you.       Feel free to present your work samples or a portfolio of written papers accompanying your submission.       You should make high quality copies that adhere to our target audiences, include visitors to the website and develop knowledge of the brand.",
            img_path: CareerWordpress,
            job_responsibilities: [
                "• Conduct in depth analysis on subjects relating to industrial applications in order to improve their original material.",
                "• To create well-structured programs, use content management systems",
                "• Content searches for errors and inconsistencies",
                "• Collaborate with communications and design departments to explain the articles your work to editors for input and acceptance",
                "• To study the keywords and use best practices to increase the traffic in the company's website via social media,",
                "• Identify consumer needs and fix discrepancies in the content of the company, propose new topics",
                "• Enhance reading and polishing of existing content",
                "• Support the marketing team in the production of publicity campaign material",
            ],
            job_requirements: [
                "• A BA in Linguistics, Journalism, or a closely related field",
                "• Content Writing/Copyright experience",
                "• Expert in managing contents",
                "• Workflow in all Microsoft Office apps",
                "• Availability of published articles",
                "• Excellent writing skills",
                "• Ability to comply with deadline",
                "• Ability to process them simultaneously",
                "⇩",
                "Please send your CV to apply -info[@]eliteglobesolutions.com",
                ,
            ]
        },
        {
            id: '5c5',
            title: 'Content Editor',
            job_description: "In order to maintain a productive user interface, content editors work in close collaboration with marketing and graphical design departments. Rules of responsibility cover media, communications, and advertisement content preparation, drafting, proofreading, and coordination and publishing.          Create proposals for different briefs; create marketing manuals, traffic and site measurements reporting, and edit content to follow SEO best practices.Content editors write content in various formats, blogs, websites, newsletters, leaflets, white papers, and so forth․",
            job_overview: "In order to handle our publishing needs as well as composing, organizing, editing, and posting web material we are searching for a smart content editor. You will collaborate to create and curate entertaining content for our audiences through a team of writers and designers. Your primary responsibility includes brand strategy, proofreading and reviewing, analysis of readership outcomes and conception of our content strategy.           You’ll need experience creating and editing content, as well as outstanding communication and organizational skills to succeed in this role.You should have a bachelor’s degree in English or a related area.          In order to succeed in this position, you will need experience designing and editing contents, and excellent communication and organizational skills.A bachelor's degree in English or an associated field should be given.",
            img_path: CareerSeo,
            job_responsibilities: [
                "• Emerging Blog posts, newsletters, websites and booklets",
                "• Check material written by other team members0",
                "• Prepare and manage a content schedule to ensure that new content is posted regularly via different social media platforms",
                "• When editing and optimizing content the SEO recommendations should be adopted",
                "• Provide fresh marketing innovations that can push direct traffic to the blog",
                "• Content Manager, Content Creator and Content Strategist for content creation",
                "• Maintain the latest information about how to write corporate content and build appealing content development principles.",
                "• Specify and edit all spelling, grammar and syntax",
                "• Work together with the graphic design team to ensure a user-friendly interface",
                "• Organizing a busy workload in time",
                "• Increasing reader satisfaction and engagement via analysis",
            ],
            job_requirements: [
                "• A Bachelor’s degree or Master’s degree linguistics, communication, journalism, or other related field",
                "• Portfolio",
                "• 3+ years of experience in the specified field in the Marketing department",
                "• Excellent verbal skills",
                "• Strong leadership and organizational skills",
                "• Excellent time management skills",
                "• Excimental skills in written communication",
                "• Creativeness",
                "• SEO and keyword review comprehension",
                "• Strong job ethics",
                "• Specialist at SEO and keyword analysis",
                "⇩",
                "To apply, please send your resume to careers –info[@]eliteglobesolutions.com"
            ]
        },
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.location.state]);


    return (
        <div className="careersPage">
            <Helmet>
                <title>{props.location.state.title} - Eliteglobesolutions</title>
                <link rel="canonical" href="http://eliteglobesolutions.com/careers/careersPage" />
                <meta name="description" content="Burbank SEO Company" />
            </Helmet>
            <div className="container">
                {data.filter(item => item.id === props.location.state.id).map(item => (
                    <div key={item.id} className="careersPage__main">
                        <div className="careersPage__main-content">
                            <h2>{item.title}</h2>
                            <p className="decorated_p">Job Description</p>
                            <p>{item.job_description}</p>

                            <p className="decorated_p">Job Overview</p>
                            <p>{item.job_overview}</p>

                            <p className="decorated_p">Responsibilities of {item.title}</p>
                            <div> {item.job_responsibilities.map(el => (
                                <p key={`${item.id}career`}>{el}</p>
                            ))}
                            </div>

                            <p className="decorated_p">Resquirements of {item.title}</p>
                            <div> {item.job_requirements.map(el => (
                                <p key={`${item.id}careers`}>{el}</p>
                            ))}
                            </div>
                        </div>

                        <div className="careersPage__main-img" >
                            <img src={item.img_path} alt="careersPage-img" />
                        </div>
                    </div >
                ))}

            </div >
        </div >
    )
}

export default CareersPage
