import React from 'react';
import './Description.scss';
import { FaGoogle } from 'react-icons/fa';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiMarketo } from 'react-icons/si';
import { IoBriefcaseOutline } from 'react-icons/io5';
import { CgWebsite } from 'react-icons/cg';

function Description() {
    return (
        <div className="description">
            <div className="container">
                <div className="description__main">
                    <h1 className="sec_title" data-aos="zoom-in" data-aos-offset="300">Why Elite Globe Solutions is<br />refreshingly Honest ?</h1>

                    <div className="description__card-wrap">
                        <div className="description__card" data-aos="zoom-out-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div className="description__card-inner">
                                <div className="description__card-face">
                                    <IoBriefcaseOutline className="description__card-icons" />
                                    <h3>SEO SERVICES</h3>
                                </div>

                                <div className="description__card-face back">
                                    <ul>
                                        <li>SEO Strategy and Management</li>
                                        <li>Local SEO</li>
                                        <li>SEO Copywriting</li>
                                        <li>SEO Audit</li>
                                        <li>On & Off Page SEO</li>
                                        <li>Backlinks Strategy and Management</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="description__card" data-aos="zoom-out-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div className="description__card-inner">
                                <div className="description__card-face">
                                    <CgMediaPodcast className="description__card-icons" />
                                    <h3>SOCIAL MEDIA SERVICES</h3>
                                </div>

                                <div className="description__card-face back">
                                    <ul>
                                        <li>Social Media Strategy and Management</li>
                                        <li>Social Influencer Marketing</li>
                                        <li>Social Content Creation</li>
                                        <li>Social Posting and Automation</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="description__card" data-aos="zoom-out-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div className="description__card-inner">
                                <div className="description__card-face">
                                    <FaGoogle className="description__card-icons" />
                                    <h3>GOOGLE LOCAL AND PAID SERVICES</h3>
                                </div>

                                <div className="description__card-face back">
                                    <ul>
                                        <li>Google Local Business</li>
                                        <li>Google Webmaster Search Console</li>
                                        <li>Google Analytics Services</li>
                                        <li>Pay Per Click (PPC) and AdWords Management</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="description__card" data-aos="zoom-out-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div className="description__card-inner">
                                <div className="description__card-face">
                                    <CgWebsite className="description__card-icons" />
                                    <h3>WEB DESIGN & DEVELOPMENT SERVICES</h3>
                                </div>

                                <div className="description__card-face back">
                                    <ul>
                                        <li>Web Design & Development</li>
                                        <li>Mobile Website Development</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="description__card" data-aos="zoom-out-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div className="description__card-inner">
                                <div className="description__card-face">
                                    <SiMarketo className="description__card-icons" />
                                    <h3>OTHER DIGITAL MARKETING SERVICES</h3>
                                </div>

                                <div className="description__card-face back">
                                    <ul>
                                        <li>Digital Brand Strategy</li>
                                        <li>Conversion Rate Optimization</li>
                                        <li>E-Commerce Platform Development</li>
                                        <li>Mobile & Tablet Solutions</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="description__bottom" data-aos="zoom-in" data-aos-offset="300">
                        <h2 className="sec_title description__google">GOOGLE 1ST PAGE RESULT</h2>

                        <p>
                            *1ST PAGE GUARANTEED RESULT AFTER 3 MONTHS UP TO 1 YEAR DEPENDS ON YOUR LOCATION AND COMPETITORS.
                        </p>
                        <br />
                        <p>
                            SEE OUR RESULTS BELOW. ONCE YOUR BUSINESS IS ON THE 1ST PAGE YOU WILL GET 30% OFF.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Description;
