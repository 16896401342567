import './ExperienceServices.scss';

function ExperienceServices(props) {
    return (
        <div className="experience">
            <div className="container">
                <div className="experience__between">
                    <div className="experience__flex" data-aos="zoom-in" data-aos-offset="300">
                        <div className="experience__flex-age">
                            <span>15</span>
                            <p>YEARS`<br /> EXPERIENCE<br /> IN IT SPHERE</p>
                        </div>

                        <h3>{props.title}</h3>
                    </div>

                    <p className="experience__between-p" data-aos="zoom-in" data-aos-offset="300">{props.text}</p>
                </div>
            </div>
        </div>
    )
}

export default ExperienceServices;
