import { Helmet } from "react-helmet";
import HighArticle from '../../highArticle/HighArticle';
import './Portfolio.scss';
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai';
import Tabs from './Tabs.jsx'
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../index";
import { collection,getDocs } from 'firebase/firestore'

function Portfolio() {
    const {db,store} = useContext(Context)
    const data = store.getState().portfolio.data
    const messages = collection(db, 'messages')
    const portfolioData = store.getState().portfolio.data2
    const [portfolioDataState,setPortfolioDataState] = useState(null)




    return (
        <div>
            <Helmet>
                <title>Digital Visibility Concepts | Burbank SEO Company</title>
                <link rel="canonical" href="http://eliteglobesolutions.com/portfolio" />
                <meta name="description" content="Burbank SEO Company" />
            </Helmet>
            <HighArticle name='Portfolio' />
            <div className="portfolio">
                <div className="container">
                    <div className="portfolio__tab" data-aos="zoom-in" data-aos-offset="300">
                        <Tabs>
                            <div label="All">
                                <div className="portfolioItems">
             {
                                        portfolioData.map(item => (
                                            <Link to={`/portfolio/${item.path}`}
                                                className="singlePortfolio"
                                                key={item.id}
                                            >
                                                <div className="port_img">
                                                    <img src={item.img} alt="" />
                                                </div>
                                                <h3>{item.mainTitle}</h3>

                                                <div className="arrowbtn"><AiOutlineArrowRight className="arrowbtn-icon"/></div>
                                            </Link>
                                        ))
                                    }
                                </div>
                            </div>
                            <div label="SEO">
                                <div className="portfolioItems">
                                    {
                                        portfolioData.filter(item => item.type === 'seo').map(item => (
                                            <Link to={`/portfolio/${item.path}`}
                                                className="singlePortfolio"
                                                key={item.id}
                                            >
                                                <div className="port_img">
                                                    <img src={item.img} alt="" />
                                                </div>
                                                <h3>{item.mainTitle}</h3>
                                                <div className="arrowbtn"><AiOutlineArrowRight /></div>
                                            </Link>
                                        ))
                                    }
                                </div>
                            </div>


                            <div label="Website Development">
                                <div className="portfolioItems">
                                    {
                                        portfolioData.filter(item => item.type === 'web').map(item => (
                                            <Link to={`/portfolio/${item.path}`}
                                                className="singlePortfolio"
                                                key={item.id}
                                            >
                                                <div className="port_img">
                                                    <img src={item.img} alt="" />
                                                </div>
                                                <h3>{item.mainTitle}</h3>
                                                <div className="arrowbtn"><AiOutlineArrowRight /></div>
                                            </Link>
                                        ))
                                    }
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Portfolio;
