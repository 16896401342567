import { NavLink } from 'react-router-dom';
import './CompanySection.scss';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';
import { BsChevronDown } from 'react-icons/bs';

function CompanySection() {
    return (
        <section className="company">
            <div className="container">
                <div className="company__card">
                    <div className="company__about">
                        <h6>OUR COMPANY</h6>

                        <span className="company__span">Full-Service Digital Marketing Agency in LA. We’ve been thriving in
                            <span className="company__span-back"> 15 years.</span>
                        </span>

                        <p>As a leading Digital Marketing Agency, we don’t do cookie-cutter services.</p>

                        <p>
                            Building your online presence is about more than just being present. Our team members have a huge amount of experience in
                            <NavLink className="link-selected" to={{ pathname: '/small-business' }} target={"_blank"} exact> Digital Media</NavLink>, therefore, we specialize in
                            <NavLink className="link-selected" to={{ pathname: '/web-design-development' }} target={"_blank"} exact> Web & Graphic Design</NavLink>, and Digital Marketing. We put professionalism, knowledge, expertise, and creativity to deliver the best solutions for our clients.
                            We have a deep understanding of:
                        </p>

                        <ul className="company__list">
                            <li>Corporate Identity</li>
                            <li>
                                <NavLink className="link-selected" to={{ pathname: '/seo' }} target={"_blank"} exact >Marketing SEO (Search Engine Optimization)</NavLink>
                            </li>
                            <li>Social Media</li>
                            <li>Mobile Application</li>
                            <li>Website Maintenance</li>
                        </ul>

                        <p>
                            Elite Globe Solutions team design creative, competitive, and professional websites that involve all the elements necessary to your immediate presence on the Internet.
                            We present premium class services at reasonable prices that are affordable to our customers.
                            Our strategies help to increase their reputation and bring benefits to the business. Due to our projects regular follow-ups, training, and online support before and after the website launch, we strive to have at least 99% customer satisfaction.
                        </p>

                        <p>
                            As a <NavLink className="link-selected" to={{ pathname: '/about-us' }} target={"_blank"} exact>Digital Marketing Agency</NavLink>, we work extremely hard to create and deliver tailored solutions that meet both your objectives and budgetary needs.
                            Our company was founded in 2016 in, Burbank CA.
                        </p>

                    </div>


                    <Accordion className="company__accordion">

                        <AccordionItem className="company__accordion-item" data-aos="fade-right" data-aos-offset="350">
                            <AccordionItemHeading>
                                <AccordionItemButton className="company__accordion-btn">
                                    <p>What are you specialized in?</p> <BsChevronDown className="company__accordion-chevron" />
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="company__accordion-panel">
                                <p>
                                    We are a provider of end-to-end marketing services narrowed down in business expansion. Holdings represent the overall technical and quick-witted human resources carrying out reckons on web design, SEO, branding, social media, PPC, SEM supplementary.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem className="company__accordion-item" data-aos="fade-left" data-aos-offset="350">
                            <AccordionItemHeading>
                                <AccordionItemButton className="company__accordion-btn">
                                    <p>How long have you been introducing your services?</p> <BsChevronDown className="company__accordion-chevron" />
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="company__accordion-panel">
                                <p>
                                    We have become an inseparable part of the industry since 2007. Newly generating companies have come and gone contributing irreplaceable competitive advantages.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>


                        <AccordionItem className="company__accordion-item" data-aos="fade-right" data-aos-offset="350">
                            <AccordionItemHeading>
                                <AccordionItemButton className="company__accordion-btn">
                                    <p>What is the goagraphic of your operations?</p> <BsChevronDown className="company__accordion-chevron" />
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="company__accordion-panel">
                                <p>
                                    Ours is an LA-based company sustaining intercontinental distant disclosure enabling to fulfill schemes, ventures via E-contacts (counting chats, phone calls, video imparting).
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem className="company__accordion-item" data-aos="fade-left" data-aos-offset="350">
                            <AccordionItemHeading>
                                <AccordionItemButton className="company__accordion-btn">
                                    <p>What kind of clients do you usually work with?</p> <BsChevronDown className="company__accordion-chevron" />
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="company__accordion-panel">
                                <p>
                                    We service over 20 industries including retail, e-commerce, entertainment, real estate, media, legal, home improvement, automotive, financial, services, beauty, fashion, and more. We provide every marketing aspect for a successful online presence. But, we haven’t come across industry we could not master.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem className="company__accordion-item" data-aos="fade-right" data-aos-offset="350">
                            <AccordionItemHeading>
                                <AccordionItemButton className="company__accordion-btn">
                                    <p>What is the main sector that you are serving?</p> <BsChevronDown className="company__accordion-chevron" />
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="company__accordion-panel">
                                <p>
                                    Setting out multi-dimensional domains we cover e-commerce heedless of financial services, beauty, fashion, electronics, toys, sports, gardening, home decoration multitudinous quarters warranting the fruitful presence of machine-accessible, non-machine-accessible environments. No trade or corporation escapes handling.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem className="company__accordion-item" data-aos="fade-left" data-aos-offset="350">
                            <AccordionItemHeading>
                                <AccordionItemButton className="company__accordion-btn">
                                    <p>Are web hosting services available with you?</p> <BsChevronDown className="company__accordion-chevron" />
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="company__accordion-panel">
                                <p>
                                    Purchasers persistently keep the track of ours to receive updates, future-needed management, into the bargain we superintend hundreds of websites, pages, servers.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem className="company__accordion-item" data-aos="fade-right" data-aos-offset="350">
                            <AccordionItemHeading>
                                <AccordionItemButton className="company__accordion-btn">
                                    <p>The implement of social marketing tools is common with you?</p> <BsChevronDown className="company__accordion-chevron" />
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="company__accordion-panel">
                                <p>
                                    Appraisal of triumphal tops occurs resultantly with the introduction of reputation scale assessment. Analytics auspiciously enhances ROI based tools enlightening hidden horizons of possibilities bringing the edges together.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem className="company__accordion-item" data-aos="fade-left" data-aos-offset="350">
                            <AccordionItemHeading>
                                <AccordionItemButton className="company__accordion-btn">
                                    <p>Do you possess google adwords certificate?</p> <BsChevronDown className="company__accordion-chevron" />
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="company__accordion-panel">
                                <p>
                                    The level of expertise is not based on individual appraisals but appropriate certificates indicating the professionalism timed by expertise.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                    </Accordion>
                </div>
            </div>
        </section>
    )
}

export default CompanySection;
