import './HighArticle.scss';
import { Link } from 'react-router-dom';

function HighArticle(props) {
    return (
        <div className="highArticle">
            <div className="container">
                <div className="highArticle__box" data-aos="zoom-in" data-aos-offset="300">
                    <h1>{props.name}</h1>

                    <div className="highArticle__box-link">
                        <Link className="highArticle__box-redirect" to='/'>Home / </Link>
                        <p>{props.name}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HighArticle;
