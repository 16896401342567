import { NavLink, Link } from 'react-router-dom';
import './Footer.scss';
import '../header/Header.scss';
import FooterLogo from '../../assets/img/footer-logo.png';
import { RiFacebookFill } from 'react-icons/ri';
import { RiInstagramLine } from 'react-icons/ri';
import { RiTwitterFill } from 'react-icons/ri';
import { RiLinkedinBoxFill } from 'react-icons/ri';
import { BiMapPin } from 'react-icons/bi';
import { GoMailRead } from 'react-icons/go';
import { BsTelephoneOutbound } from 'react-icons/bs';
import { BsChevronDoubleUp } from 'react-icons/bs';

function Footer() {

    const Mailto = ({ email, subject = '', body = '', children }) => {
        let params = subject || body ? '?' : '';
        if (subject) params += `subject=${encodeURIComponent(subject)}`;
        if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

        return <a className="footer__link" href={`mailto:${email}${params}`}>{children}</a>;
    };

    return (
        <footer className="footer">
            <div className="container">
                <nav className="footer__nav">
                    <div className="footer__nav-about">
                        <NavLink to='/' exact>
                            <img className="footer__logo" src={FooterLogo} alt="Logo" />
                        </NavLink>

                        <div className="footer__href">
                            <BiMapPin className="footer__href-icon" />
                            <a href="https://goo.gl/maps/mzWfY51tNudi2R3bA" target={"/blank"}>621 S Victory Blvd unit 3, Burbank, CA 91502</a>
                        </div>

                        <div className="footer__href">
                            <GoMailRead className="footer__href-icon" />
                            <Mailto className="footer__mail" email="info@eliteglobesolutions.com" subject="Hello & Welcome" body="Hello!">info@eliteglobesolutions.com</Mailto>
                        </div>

                        <div className="footer__href">
                            <BsTelephoneOutbound className="footer__href-icon" />
                            <a className="footer__link" href="tel:+1 (818) 878-8081">+1 (818) 878-8081</a>
                        </div>
                    </div>

                    <div className="footer__nav-lists">
                        <ul className="footer__nav-list"><span>SERVICES</span>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/cold-calls'}} target={"_blank"} exact>Cold calls</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/ecommerce-seo'}} target={"_blank"} exact>E-commerce SEO</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/email-marketing'}} target={"_blank"} exact>E-mail Marketing</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/ppc'}} target={"_blank"} exact>PPC</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/seo'}} target={"_blank"} exact>SEO</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/shopify'}} target={"_blank"} exact>Shopify</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/small-business'}} target={"_blank"} exact>Small Business</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/social-media-management'}} target={"_blank"} exact>Social Media Management</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/web-design-development'}} target={"_blank"} exact>Web Design & Development</NavLink></li>
                        </ul>

                        <ul className="footer__nav-list"><span>LOCATION /AREAS</span>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/burbank-digital-marketing'}} target={"_blank"} exact>Burbank</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/glendale-telemarketing'}} target={"_blank"} exact>Glendale</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/hollywood-digital-marketing '}} target={"_blank"} exact>Hollywood</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/northhollywood-mobile-marketing'}} target={"_blank"} exact>North Hollywood</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/pasadena-mobile-game-development'}} target={"_blank"} exact>Pasadena</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/westhollywood-telehealth-marketing'}} target={"_blank"} exact>West Hollywood</NavLink></li>
                        </ul>

                        <ul className="footer__nav-list"><span>SUPPORT</span>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/faqs'}} target={"_blank"} exact>Help & FAQ</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/contact-us'}} target={"_blank"} exact>Contact Us</NavLink></li>
                            <li><NavLink className="footer__nav-link" to={{ pathname: '/packages'}} target={"_blank"} exact>Pricing & Plans</NavLink></li>
                        </ul>
                    </div>
                </nav>

                <button onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }} className="btn-top">
                    <p className="footer__i"><BsChevronDoubleUp className="footer__i-icon" /> BACK TO TOP</p>
                </button>

                <div className="footer__bottom">
                    <p>© 2022 Elite Globe Solutions. All Rights Reserved | By Elite Globe Solutions</p>

                    <div className="footer__social">
                        <Link className="footer__social-icon" to={{ pathname: "https://www.facebook.com/eliteglobesolutions/ " }} target={"_blank"}><RiFacebookFill /></Link>
                        <Link className="footer__social-icon" to={{ pathname: "https://www.instagram.com/eliteglobesolutions/ " }} target={"_blank"}><RiInstagramLine /></Link>
                        <Link className="footer__social-icon" to={{ pathname: "https://twitter.com/elite_globe " }} target={"_blank"}><RiTwitterFill /></Link>
                        <Link className="footer__social-icon" to={{ pathname: "https://www.linkedin.com/company/elite-globe-web-development-digital-marketing/" }} target={"_blank"}><RiLinkedinBoxFill /></Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
