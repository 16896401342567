import { combineReducers, createStore } from "redux";
import blogReducer from "./blog-reducer";
import { portfolioReducer } from "./portfolio-reducer";


const reducers = combineReducers({
    portfolio:portfolioReducer,
    blog:blogReducer
})

const store = createStore(reducers)

export default store