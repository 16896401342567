import './App.scss';
import { useContext, useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Preloader from './components/preloader/Preloader';
import Header from '../src/components/header/Header';
import ScrollToTop from './components/scrollTop/ScrollToTop';
import Home from '../src/components/pages/home/Home';
import AboutUs from './components/pages/company/AboutUs';
import ChooseUs from './components/pages/company/ChooseUs';
import FAQs from './components/pages/company/FAQs';
import Careers from './components/pages/company/Careers';
import CareersPage from './components/pages/company/CareersPage/CareersPage'
import Seo from './components/pages/services/Seo';
import ColdCalls from './components/pages/services/ColdCalls';
import EcommerceSeo from './components/pages/services/Ecommerceseo';
import SmallBusiness from './components/pages/services/SmallBusiness';
import EmailMarketing from './components/pages/services/Emailmarketing';
import SocialMediaManagement from './components/pages/services/SocialMediaManagement';
import Ppc from './components/pages/services/Ppc';
import WebDesignDevelopment from './components/pages/services/WebDesignDevelopment';
import Shopify from './components/pages/services/Shopify';
import Burbank from './components/pages/services/Burbank';
import Hollywood from './components/pages/services/Hollywood';
import NorthHollywood from './components/pages/services/NorthHollywood';
import Pasadena from './components/pages/services/Pasadena';
import WestHollywood from './components/pages/services/WestHollywood';
import Glendale from './components/pages/services/Glendale';
import Packages from '../src/components/pages/packages/Packages';
import Portfolio from '../src/components/pages/portfolio/Portfolio';
import Blog from '../src/components/pages/blog/Blog';
import BlogPage from '../src/components/pages/blog/blogPage/BlogPage';
import LowArticle from './components/lowArticle/LowArticle';
import Footer from './components/footer/Footer';
import Contacts from './components/pages/contacts/Contacts';
import notFound from './components/pages/404/notFound';
import Admin from './components/admin/Admin';
import PortfolioId from './components/pages/portfolio/PortfolioId';
import {collection,getDocs} from 'firebase/firestore'
import { Context } from './index';
import AdminBlog from './components/admin/AdminBlog';
import Privacy from './components/pages/privacy/Privacy';

function App() {
  const {db,store} = useContext(Context)
  const messages = collection(db, 'messages')
  const blog = collection(db, 'blog')
  const portfolioData = store.getState().portfolio.data2
  const blogData = store.getState().blog
  const [PortfolioDataState,setPortfolioDataState] = useState(null)
  const [blogDataState,setBlogData] = useState(null)


  useEffect(() => {
    Aos.init({ duration: 2000 });
    getDocs(messages).then(response => {
      response.docs.map(doc => {
          setPortfolioDataState([...portfolioData, portfolioData.push(doc.data())])
      })
    })
    getDocs(blog).then(response => {
      response.docs.map(doc => {
          setBlogData([...blogData, blogData.unshift(doc.data())])
      })
    })
  }, [])

  return (
    <>
      <Router>
        <Preloader />
        <Header />
        <ScrollToTop />
        <Switch>
          {/* Admin Part  */}
          <Route exact path='/admin' component={Admin} />
          <Route exact path='/adminblog' component={AdminBlog} />
          {/* End Admin Part */}
          <Route exact path='/' component={Home} />
          {/* About Part */}
          <Route exact path='/about-us' component={AboutUs} />
          <Route exact path='/choose-us' component={ChooseUs} />
          <Route exact path='/faqs' component={FAQs} />
          <Route exact path='/careers' component={Careers} />
          <Route exact path='/careers/careers-page' component={CareersPage} />
          <Route exact path='/privacy-policy' component={Privacy} />
          {/* Service Part> */}
          <Route exact path='/seo' component={Seo} />
          <Route exact path='/cold-calls' component={ColdCalls} />
          <Route exact path='/ecommerce-seo' component={EcommerceSeo} />
          <Route exact path='/small-business' component={SmallBusiness} />
          <Route exact path='/email-marketing' component={EmailMarketing} />
          <Route exact path='/social-media-management' component={SocialMediaManagement} />
          <Route exact path='/ppc' component={Ppc} />
          <Route exact path='/web-design-development' component={WebDesignDevelopment} />
          <Route exact path='/shopify' component={Shopify} />
          <Route exact path='/burbank-digital-marketing' component={Burbank} />
          <Route exact path='/glendale-telemarketing' component={Glendale} />
          <Route exact path='/hollywood-digital-marketing' component={Hollywood} />
          <Route exact path='/northhollywood-mobile-marketing' component={NorthHollywood} />
          <Route exact path='/pasadena-mobile-game-development' component={Pasadena} />
          <Route exact path='/westhollywood-telehealth-marketing' component={WestHollywood} />
          {/* Other pages */}
          <Route exact path='/packages' component={Packages} />
          <Route exact path='/contact-us' component={Contacts} />
          {/* Blog */}
          <Route exact path='/blog' component={Blog} />
          <Route path='/blog/:url' component={BlogPage} />
          {/* Portfolio */}
          
          <Route exact path='/portfolio' component={Portfolio} />
          <Route exact path='/portfolio/:id' component={PortfolioId} />

          <Route component={notFound} />
        </Switch>
        <LowArticle />
        <Footer />
      </Router>
    </>
  );
}

export default App;
