import React from 'react';
import './HomeBlog.scss';
import { Link } from 'react-router-dom';
import BlogPic1 from '../../../assets/img/BlogPic4.jpg';
import BlogPic2 from '../../../assets/img/BlogPic2.jpg';
import BlogPic3 from '../../../assets/img/BlogPic3.jpg';
import BlogPic4 from '../../../assets/img/BlogPic1.jpg';

import { ImCalendar } from 'react-icons/im';

function HomeBlog() {

    const data = [
        {
            id: "1n1",
            cont: {
                img: BlogPic1,
                name: 'MARKETING',
                title: 'Content Marketing VS. Traditional Adverting',
                link: '/blog/blog-page',
                date: '2021-06-25',
                textPart: 'Using the profit accumulator is one such way, and you should understand why it increases your chances of the profits. …',
                text1: 'Selling as a process of interchanging goods does not fulfill expectations. Impetus of “seizing” clientele has undergone declination. It’s all-accepted interruptive, obstructive, and intrusive. Resembling shouting “Look at me!” span waving its arms right before you.',
                text2: 'Trying to avoid eye contact related to persistency of eye-catching, unnecessary info. Obligations of seeing something outrages inverse upshots. Considering machine-accessible commencing we distinguish betwixt below-mentioned categories:',
                about: 'Ads have been around for a long time since the first half of the 20th century. They still had chance of working in strategic places.Signees just click away from the predetermined area of mercantile significance stretching across pages they are not intended to do the further proceedings.',
                text3: 'According to analysis, an immense number of devices in use implied blockers installed in their browsers. It is common knowledge, contexts are annoying diverging, moving away from initial justifications of representing brand-new products. Companies need a more gentle, careful approach instead of jumping out showing in purchasers’ faces.',
                text4: 'Brands, marketers publishing material on teaching, inspiring, guiding, solving problems for target audience use hawk tools. Needing few tricks, target audience is enabled finding content on web without pushing at them. In addition finding interesting issues in content, they’ll keep coming back for further out-turns.',
                text5: 'In due course consumers interact with the producer organically sharing their text-embodiments on social media. Trust is forged, authority is established and connections happen. All of this happens when you focus on giving value to users. Help them by offering value rewarding you in return with their attention, new users.',
            }
        },
        {
            id: "2n2",
            cont: {
                img: BlogPic2,
                name: 'SMM',
                title: 'SMM: The Way Of Becoming Famous Online',
                link: '/blog/blog-page',
                date: '2019-07-13',
                textPart: 'They play a role in making operations more seamless, bridging the gap between authorities, consumers and businesses. …',
                text1: 'Social Media Marketing is internet mercantile implementing networking sites, promoting products. Rocketing traffic, learning from undeviating reactions promotes the conversion of stratagem.Policy is solidly connected to organic search. When the website or its tied community-based criss-cross page is active, it has a higher locality on search engines (the website will be seen in the opening few out-turns).',
                text2: 'The vast majority of people don’t even look further than introductory page looking for something machine-accessible, 70% choose one of elemental three aggregates only. It turns obvious – the position on the fundamental page is the ruling initiative to strive for when optimizing a processing system.',
                about: 'The main goal is improving means of communicating with your users, evolving your brand visibility, expanding the reach of more purchasers. Getting the best results from SMM creating quality text-information parallel to the requirements of media pulses.',
                text3: 'Enterprises use SMM to receive direct feedback from their clients so that the company appears more human-like. Thanks to that customers get the chance to be heard, either by asking questions or by making complaints. This is called Social Customer Relation Management, which can increase your ROI, in case your customers are satisfied with your content, have good relationships with the company, and like service provided.',
                text4: 'Functions on the principle of Optimization persistently advantaging your enterprise image on public networks. This works just like SEO, i.e. attracts new, unique visitors to targeted website.',
                text5: 'The main reason for taking societal networks is the possibility of introducing a deliberate choice of commodities with all-embracing available nuances.',
            }
        },
        {
            id: "3n3",
            cont: {
                img: BlogPic3,
                name: 'MARKETING',
                title: 'Avoiding Digital Marketing',
                link: '/blog/blog-page',
                date: '2020-03-21',
                textPart: 'It is far wiser to do your own installations and ensure that all data flowing through the organization has end-to-end encryption. …',
                text1: 'Numerous small-scale enterprise owners fight shy of matter-of-fact marketing policy, trumping up with varieties of reasons for behaving aforesaid. Conclusively, utmost company reaches desired implementation point.',
                text2: 'Axial problem is-  intermittently believing that they don’t have time or money for embellishing competitive online. The unhealthy consideration of artificial positivism regarding issues of ” primary” significance draws attention from challenges determinant in the sector. Indubitably, constant learning ins, outs of commerce realistically, representing focal impetus of taking things leisurely, sticking with one-two basic forms of advertising. Considering trade will evolve as time passes on its own.',
                about: 'Establishments appraise  best-possible strategy of advancing-unambiguously waiting for clients’ expansion of all-embracing potential. They may think, that since it’s a small business, they need a small number of purchasers.',
                text3: 'This approach is not effective at all, because you can never get a guarantee that your business will attract abundant vendors fair-mindedly by existing. Even if it does, you will not have a multitude of customers as you need to stay on the float.',
                text4: 'Just remember a simple truth: today, your customers and competitors are online. Advancement likelihood arises when appearing the of occurrences. Routinely maintaining touch with potential, actual customers.',
                text5: 'Communication is all, if people are able to get in touch with you smoothly, you are hitherto on the road to becoming successful.',
            }
        },
        {
            id: "4n4",
            cont: {
                img: BlogPic4,
                name: 'BUSINESS',
                title: 'Small Business Vs. Big Business',
                link: '/blog/blog-page',
                date: '2019-10-19',
                textPart: 'The middle part is called the emotional, brain-controlled feeling. The outer part controls logical reasoning. …',
                text1: 'In-the-first-place big businesses possess advantage in SEO (involving entire departments working the goal), implementation of right strategy finding weak spots, fight way to the top, featureless startups. Focusing on strengths empowers triumphal expediencies.',
                text2: 'If an entrepreneur wants to compete with another, that’s successful or even #1 web, they’d have to spend at least $1 million yearly generating sufficient targeted organic traffic.',
                about: 'Of course, not every type of writing has the genuine right to be a king. In fact, lousy content really hurts search engine rankings, driving away anticipated leads. Well-written, well-targeted envisage trimples probabilities.',
                text3: 'Marketers remain loyal to cliché: “content emerging king”. This comes from Bill Gates’ thesis, originally altered “Content is where I expect much of real money will be made on the Internet, just as it was in broadcasting”. He predicted it would be an important part of earning online. But it can be easily misinterpreted. Numerous corporations think representation via texts has embodiment of mentioned cycle: content = views =leads = financial means.',
                text4: 'Expanded industries transpire websites spiced with appalling, low-quality contentment. Seeing pages, considering they somewhat get along.',
                text5: 'f your enterprise is small-scaled, likelihood you don’t have the same cash to wasting on advertising, marketing that big companies do. Going “all-in” is not a solution. Alternatively, earmarking the relevant assemblage, developing an efficient strategy. You prioritize scarce high-value keywords or buy a handful of low-cost ads. Thanks to their size, they have a lot of “built-in” spectators. That counts for a lot in SEO and creates positive feedback towards establishments. Inflow wins higher ranks.',
            }
        },
    ];

    return (
        <div className="homeBlog">
            <div className="container">
                <div className="homeBlog__row">
                    {data.slice(0, 3).map(item => (
                        <div key={item.id} className="homeBlog__col">
                            <Link to={{
                                pathname: item.cont.link,
                                state: {
                                    id: item.id
                                }
                            }}
                                className="homeBlog__col-link">
                                <div className="homeBlog__card-img">
                                    <img src={item.cont.img} alt="Blog Post" />
                                </div>

                                <p>
                                    <ImCalendar className="homeBlog__card-icon" />{item.cont.date}
                                </p>

                                <h2>
                                    {item.cont.title}
                                </h2>

                                <h6>
                                    {item.cont.textPart}
                                </h6>
                            </Link>

                            <Link className="btn-redirect btn-center" to={{
                                pathname: item.cont.link,
                                state: {
                                    id: item.id
                                }
                            }}>Read More
                            </Link>

                        </div>


                    ))}
                </div>
            </div>
        </div>
    )
}

export default HomeBlog;
