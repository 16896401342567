import { Helmet } from "react-helmet";
import './Careers.scss';
import HighArticle from '../../highArticle/HighArticle';
import { Link } from 'react-router-dom';

function Careers() {

    const data = [
        {
            id: '1c1',
            title: "SEO Manager",
            time: "Full time",
            description_part: "Development and application of SEO strategies to allow the digital marketing team to conduct efficient promotions, as well as to improve the website, social media and other content activities of the firm. Content coordination, architecture, social media, communications (PPC) and other practices. Content coordination. Projects and results reporting management on site and on-site optimization. Analysis of continuous keywords like the search and expansion of keywords. To conduct research and enforce content guidelines on organic SEO results.",
            link: '/careers/careers-page'
        },
        {
            id: '2c2',
            title: "WordPress Developer",
            time: "Full time",
            description_part: "Developers of WordPress implement tools for designing and running company websites. The development at both the front and back end, as well as the introduction of theme and plugin the high merits of the sphere and deeper knowledge is more than mandatory. The aim is to create websites satisfying the needs of potential customers.",
            link: '/careers/careers-page'
        },
        {
            id: '3c3',
            title: "Database Administrator",
            time: "Full time",
            description_part: "Database administrators ensure smooth operation of applications. The DB Managers archive and organize the use of specialized software documents, such as financial data and customer delivery information. It ensures that the data are available to consumers and is safeguarded against unauthorized access.",
            link: '/careers/careers-page'
        },
        {
            id: '4c4',
            title: "Content Writer",
            time: "Full time",
            description_part: "Оur editing team is highly interested in involving a content writer for producing new blog posts. We are searching for a content writer that will further enrich the marketing backups of our blogs and revision papers once they are posted. Content Writer shall undertake comprehensive industry-related analysis, generate ideas for new content styles and review the papers before publishing. If you know how to make web content and are attentive to accuracy, we would like to meet you. Feel free to present your work samples or a portfolio of written papers accompanying your submission. You should make high quality copies that adhere to our target audiences, include visitors to the website and develop knowledge of the brand.",
            link: '/careers/careers-page'
        },
        {
            id: '5c5',
            title: "Content Editor",
            time: "Full time",
            description_part: "In order to maintain a productive user interface, content editors work in close collaboration with marketing and graphical design departments. Rules of responsibility cover media, communications, and advertisement content preparation, drafting, proofreading, and coordination and publishing. Create proposals for different briefs; create marketing manuals, traffic and site measurements reporting, and edit content to follow SEO best practices.",
            link: '/careers/careers-page'
        },


    ]

    return (
        <div>
            <Helmet>
                <title>PPC | SEM</title>
                <link rel="canonical" href="http://eliteglobesolutions.com/careers" />
                <meta name="description" content="Mobile and Desktop Digital Marketing Agency" />
            </Helmet>
            <HighArticle name='Careers' />

            <div className="careers">
                <div className="container">
                    <h1 className="sec_title" data-aos="zoom-in" data-aos-offset="300">Become a part of our big family to inspire and get inspired by professional experts.</h1>

                    <div className="careers__job">
                        {data.map(slide => (
                            <div key={slide.id} className="careers__col" data-aos="zoom-in" data-aos-offset="300">
                                <div className="careers__name">
                                    <h6>
                                        {slide.title}
                                    </h6>
                                    <span>
                                        {slide.time}
                                    </span>
                                </div>

                                <p>
                                    {slide.description_part}
                                </p>

                                <Link className="btn-redirect"
                                    to={{
                                        pathname: slide.link,
                                        state: {
                                            id: slide.id,
                                            title: slide.title
                                        }
                                    }}>Learn More
                                </Link>
                            </div>
                        ))}
                    </div>

                    <h2 className="sec_title" data-aos="zoom-in" data-aos-offset="300">Let’s find a way to make a win-win!</h2>
                </div>
            </div>
        </div>
    )
}

export default Careers;