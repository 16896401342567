import './Testimonials.scss';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import tt1 from '../../../assets/img/testimonial_andranik.jpg';
import tt2 from '../../../assets/img/testimonial_salma.jpg';
import tt3 from '../../../assets/img/testimonial_iryna.jpg';

const data = [
    {
        id: 1,
        text: "No business is successful without the implementation of proper digital marketing services. Currently, the sphere of digital marketing has accumulated several service providers, but eventually who to trust. As an entrepreneur who invested all his available funds and assets in the business, I simply needed a team that would think ahead of me. I assuredly claim that no other service provider rather than Elite Globe Solutions considers the goals of the clients to be their goals.",
        name: 'Andranik Martirosyan',
        img: tt1

    },
    {
        id: 2,
        text: "I accidentally came across Elite Globe solutions while surfing on the internet. I was seeking a company to thoroughly trust since I needed services of which I had very little knowledge. To my happiness and success, I found what I was exactly looking for! ",
        name: 'Salma James',
        img: tt2
    },
    {
        id: 3,
        text: "The everyday changes in the algorithms of searches on the internet is making the uses get crazy about the growing competitiveness changing the all-embracing picture of occurrences every single day! Elite Globe solutions simply took these concerns off my shoulders. Now I know whom to turn to when I have issues with optimization on the websites.",
        name: 'Iryna Zeier',
        img: tt3
    },
]

SwiperCore.use([Pagination, Autoplay]);

function SliderPartners() {
    return (
        <section className="testimonial_slide">
            <div className="container">
                <h2 className="sec_title" data-aos="zoom-in" data-aos-offset="300">Why do people praise about<br />
                    <span className="blue">Elite Globe?</span>
                </h2>


                <Swiper
                    spaceBetween={30}
                    slidesPerView={3}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    pagination={{
                        "dynamicBullets": true,
                        "clickable": true,
                    }}
                    autoplay={{
                        delay: 3000,
                    }}
                    breakpoints={{

                        "200": {
                            "slidesPerView": 1,
                        },

                        "878": {
                            "slidesPerView": 2,
                        },
                        "1600": {
                            "slidesPerView": 3,
                        }
                    }}
                >
                    {data.map(slide => (
                        <SwiperSlide
                            key={slide.id}
                        >
                            <div className="test_singleslide">
                                <div className="name_part">
                                    <div className="slide_pic">
                                        <img src={slide.img} alt="" />
                                    </div>
                                    <p>
                                        {slide.name}
                                    </p>
                                </div>
                                <div className="slide_text">
                                    {slide.text}
                                </div>

                            </div>

                        </SwiperSlide>

                    ))}
                </Swiper>
            </div>
        </section>

    )
}

export default SliderPartners
