import './SliderMarketing.scss';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { Link } from 'react-router-dom';
import sl1 from '../../../assets/img/SEO.png';
import sl3 from '../../../assets/img/On page SEO.png';
import sl4 from '../../../assets/img/Small business SEO.png';
import sl5 from '../../../assets/img/Ecommerce SEO.png';
import sl7 from '../../../assets/img/Email marketing.png';
import sl9 from '../../../assets/img/Social media management.png';
import sl11 from '../../../assets/img/Web design & development.png';
import sl13 from '../../../assets/img/PPC.png';
import sl15 from '../../../assets/img/Shopify.png';
import sl16 from '../../../assets/img/telemarketing.png';
import sl17 from '../../../assets/img/Telehealth.png';
import sl18 from '../../../assets/img/Pasadena mobile game development.png';
import sl19 from '../../../assets/img/Hollywood B2B.png';
import sl20 from '../../../assets/img/North hollywood mobile marketing.png';
import sl21 from '../../../assets/img/real estate in Burbank.png';


const data = [
    {
        id: 1,
        title: 'Cold Calls',
        img: sl3,
        link: 'cold-calls'
    },
    {
        id: 2,
        title: 'E-commerce SEO',
        img: sl5,
        link: 'ecommerce-seo'
    },
    {
        id: 3,
        title: 'E-mail Marketing',
        img: sl7,
        link: 'email-marketing'
    },
    {
        id: 4,
        title: 'PPC',
        img: sl13,
        link: 'ppc'
    },
    {
        id: 5,
        title: 'SEO',
        img: sl1,
        link: 'seo'
    },
    {
        id: 6,
        title: 'Shopify',
        img: sl15,
        link: 'shopify'
    },
    {
        id: 7,
        title: 'Small Business',
        img: sl4,
        link: 'small-business'
    },
    {
        id: 8,
        title: 'Social Media Management',
        img: sl9,
        link : 'social-media-management'
    },
    {
        id: 9,
        title: 'Web Design & Development',
        img: sl11,
        link: 'web-design-development'
    },
    {
        id: 10,
        title: 'Burbank',
        img: sl21,
        link: 'burbank-digital-marketing'
    },
    {
        id: 11,
        title: 'Glendele',
        img: sl16,
        link: 'glendale-telemarketing'
    },
    {
        id: 12,
        title: 'Hollywood',
        img: sl19,
        link: 'hollywood-digital-marketing'
    },
    {
        id: 13,
        title: 'North Hollywood',
        img: sl20,
        link: 'northhollywood-mobile-marketing'
    },
    {
        id: 14,
        title: 'Pasadena',
        img: sl18,
        link: 'pasadena-mobile-game-development'
    },
    {
        id: 15,
        title: 'West Hollywood',
        img: sl17,
        link: 'westhollywood-telehealth-marketing'
    },
]

SwiperCore.use([Pagination, Autoplay]);

function SliderMarketing() {
    return (
        <section className="slierMarketing">
            <div className="container">
                <h2 className="sec_title" data-aos="zoom-in" data-aos-offset="300">Digital marketing<br />Services</h2>
                <div className="slide_part" data-aos="fade-up">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={5}
                        loop={true}
                        loopFillGroupWithBlank={true}
                        // slidesPerGroupAuto={5}
                        // disableOnInteraction={true}
                        // pauseOnMouseEnter ={false}
                        pagination={{
                            "dynamicBullets": true,
                            "clickable": true,
                        }}
                        autoplay={{
                            delay: 5000,
                        }}
                        breakpoints={{
                            "250": {
                                "slidesPerView": 2,
                                "slidesPerGroup": 2
                            },
                            "500": {
                                "slidesPerView": 3,
                                "slidesPerGroup": 3
                            },
                            "860": {
                                "slidesPerView": 4,
                                "slidesPerGroup": 4
                            },
                            "1100": {
                                "slidesPerView": 5,
                                "slidesPerGroup": 5
                            }
                        }}

                    >
                        {data.map(slide => (
                            <SwiperSlide
                                key={slide.id}
                            >
                                <Link  to={{ pathname: `${slide.link}` }} target={"_blank"} className="single_slide">
                                    <div className="slide_img">
                                        <img src={slide.img} alt="" />
                                    </div>
                                    <h4>
                                        {slide.title}
                                    </h4>
                                </Link>

                            </SwiperSlide>


                        ))}
                    </Swiper>
                </div>
            </div>


        </section>

    )
}

export default SliderMarketing;
