import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';
import HighArticle from '../../highArticle/HighArticle';
import './AboutUs.scss';
import aboutUsPic1 from '../../../assets/img/aboutUsPic1.jpg';
import aboutUsPic2 from '../../../assets/img/aboutUsPic2.jpg';

function AboutUs() {
    return (
        <div>
            <Helmet>
                <title>Mobile and Desktop Digital Marketing Agency | SEO, SMM, SEM</title>
                <link rel="canonical" href="http://eliteglobesolutions.com/about-us" />
                <meta name="description" content="Mobile and Desktop Digital Marketing Agency" />
            </Helmet>
            <HighArticle name='About Us' />

            <div className="aboutUs">
                <div className="container">
                    <h1 className="sec_title sec_title2" data-aos="zoom-in" data-aos-offset="300">We create dreams for the future. <br />We realize them successfully.</h1>

                    <span data-aos="zoom-in" data-aos-offset="300">You know that you found your dream when you have a desire to achieve a goal, have the gut to pursue it, have the courage to overcome any obstacles on your path and feel blessed when finally realizing it. Dream on, keep up and make it pay off.</span>

                    <div className="aboutUs__box" data-aos="zoom-in" data-aos-offset="300">
                        <div className="aboutUs__box-img">
                            <img src={aboutUsPic1} alt="" />
                        </div>

                        <p>We put professionalism, knowledge, expertise, and creativity to deliver the best solutions for our clients. We have a deep understanding of corporate identity, internet, marketing, SEO (Search Engine Optimization), social media, mobile application, website maintenance.</p>
                    </div>

                    <div className="aboutUs__box" data-aos="zoom-in" data-aos-offset="300">
                        <p>Elite Globe Solutions team design creative, competitive, and professional websites that involve all the elements necessary to your immediate presence on the Internet. We present premium class services at reasonable prices that are affordable to our customers.</p>

                        <div className="aboutUs__box-img">
                            <img src={aboutUsPic2} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container">
                    <div className="content__card" data-aos="zoom-in" data-aos-offset="300">
                        <p>
                            <NavLink className="link-selected" to={{ pathname: '/seo' }} target={"_blank"} exact>SEO services </NavLink>
                            providers mostly work on the optimization of market targeted sectors preparing a profound soil for businesses’ expansion and further growth.
                            <br />
                            <br />
                            <NavLink className="link-selected" to={{ pathname: '/about-us' }} target={"_blank"} exact>Elite Globe Solutions</NavLink> – a digital marketing Agency located in LA, has processed its productive strategies collaborating with clients considering their wishes, basic necessities to be a priority in management. Companies deal with media every single day, search based involvement of the purchasers is the merits of a quality website development team.
                            <br />
                            <br />
                            Campaigns specified in advertising work on various spheres simultaneously- ever-changing design elegantly simplistic in its manner but symbolic or conservative based on the product type, making business customarily visible not archaic. If design gratifies web users, they go forward with the desire to satisfy other social needs as well- consideration falls into the identical content protruding overall attention. We will make visits to your websites a habit for millions of people having at least the smallest or the biggest chance to become habitual visitors and users.
                            <br />
                            We provide equipment-friendly design dictated by previous experiences as a consequence of gained expertise fulfilling the incredibly ambitious, forceful, determined missions outlined by the companies as an inseparable part of their strategic plan. Dynamic evolvement is not conditioned by short victories but triumphs of defending the wars being larger in its scopes and dimensions incomparable with short-term success.
                            <br />
                            <br />
                            <NavLink className="link-selected" to={{ pathname: '/small-business' }} target={"_blank"} exact>Digital marketing </NavLink>
                            is not a narration of successful previous experiences rather than a visit-card to broader extensions timing profits reducing time and efforts!
                            <br />
                            <br />
                            Now that you have made up your mind about the company to trust your “media future” imagine PPC outcomes gradually enlarging your pockets. Promises do not hang in the air like dandelion leaves cultivating seeds we ensure long-lasting out-turn! Calculations becoming more and more beneficial for you will serve as a stimulus giving a possibility of comparing present-day and previous own statistics. Controlling and maintaining achievements pilots new horizons seeming to be unattainable previously!
                            <br />
                            <br />
                            Our strategies help to increase their reputation and bring benefits to the business. Due to our projects regular follow-ups, training, and online support before and after the website launch, we strive to have at least 99% customer satisfaction.
                            <br />
                            <br />
                            Our company was founded in 2016 in, Burbank CA.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;
