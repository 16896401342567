import './ServicesTopArticle.scss';

function ServicesTopArticle(props) {
    return (
        <div className="servicesTopArticle">
            <h1 data-aos="zoom-in" data-aos-offset="300">{props.title}</h1>
        </div>
    )
}

export default ServicesTopArticle;
