import { Helmet } from "react-helmet";
import { useRef} from 'react';
import emailjs from 'emailjs-com';
import HighArticle from '../../highArticle/HighArticle';
import './Contacts.scss';

function Contacts() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_uwjtloj', 'template_c54jzda', e.target, 'user_gmLXHO5qvoc7mD6ohzjQa')
            .then(() => {
                alert("Your message is send!");
            }, (error) => {
                alert(error.text);
            });
        e.target.reset()
    };

    return (
        <div>
            <Helmet>
                <title>Digital Brand Strategy | Adwors Managment</title>
                <link rel="canonical" href="http://eliteglobesolutions.com/contact-us" />
                <meta name="description" content="Digital Brand Strategy" />
            </Helmet>
            <HighArticle name='Contacts' />
            <div className="contacts">
                <div className="container">
                    <div className="contacts__box">

                        <div className="contacts__box-title" data-aos="zoom-in" data-aos-offset="300">
                            <h1>To make requests for further information, contact us via our social channels.</h1>
                            <p>We just need a couple of hours!<br /> No more than 2 working days since receiving your issue ticket.</p>
                        </div>

                        <form className="contacts__form" ref={form} onSubmit={sendEmail} data-aos="zoom-in" data-aos-offset="300">
                            <div className="contacts__form-flex">
                                <div className="contacts__form-between">
                                    <input type="text" name="name" placeholder="Name *" required />
                                    <input type="text" name="lastname" placeholder="Last Name *" required />
                                </div>
                                <div className="contacts__form-between">
                                    <input type="email" name="email" placeholder="Email *" required />
                                    <input type="subject" name="subject" placeholder="Subject *" required />
                                </div>
                            </div>
                            <textarea name="message" placeholder="Please describe what you need." required />
                            <button className="btn-redirect btn-center" type="submit">Send</button>
                        </form>

                    </div>
                </div>
            </div>

            <div className="contacts__map container" data-aos="zoom-in" data-aos-offset="300">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3301.1132102239285!2d-118.3136117!3d34.1690231!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bfe354167e53%3A0xddfa8364c46246dd!2s621%20S%20Victory%20Blvd%20%233%2C%20Burbank%2C%20CA%2091502%2C%20USA!5e0!3m2!1sen!2s!4v1638427710599!5m2!1sen!2s" width="600" height="450"  loading="lazy" title="fdcbf"></iframe>
            </div>
        </div>
    )
}

export default Contacts;
