import { Helmet } from "react-helmet";
import './ChooseUs.scss';
import { NavLink } from 'react-router-dom';
import HighArticle from '../../highArticle/HighArticle';
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";


function ChooseUs() {

    return (
        <div>
            <Helmet>
                <title>Why choose us - EliteGlobeSolutions</title>
                <link rel="canonical" href="http://eliteglobesolutions.com/choose-us" />
                <meta name="description" content="Mobile and Desktop Digital Marketing Agency" />
            </Helmet>
            <HighArticle name='Choose Us' />

            <div className="chooseUs">
                <div className="container">
                    <h1 className="sec_title sec_title2" data-aos="zoom-in" data-aos-offset="300">Why Elite Globe Solutions is<br /> Refreshingly Honest?</h1>

                    <div className="chooseUs__flex">
                        <div className="chooseUs__flex-right" data-aos="zoom-in" data-aos-offset="300">
                            <div className="chooseUs__flex-age">
                                <span>15</span>
                                <p>YEARS`<br /> EXPERIENCE<br /> IN IT SPHERE</p>
                            </div>

                            <h3>Learn More About Our Success Stories</h3>
                        </div>

                        <div className="chooseUs__flex-sliderbar" data-aos="zoom-in" data-aos-offset="300">
                            <Box sx={{ width: 500 }}>
                                <Typography gutterBottom>UI/UX</Typography>
                                <Slider
                                    disabled
                                    defaultValue={81}
                                    aria-label="Disabled slider"
                                    valueLabelDisplay="on"
                                />
                                <Typography gutterBottom>Branding</Typography>
                                <Slider
                                    disabled
                                    defaultValue={72}
                                    aria-label="Disabled slider"
                                    valueLabelDisplay="on"
                                />
                                <Typography gutterBottom>Marketing</Typography>
                                <Slider
                                    disabled
                                    defaultValue={89}
                                    aria-label="Disabled slider"
                                    valueLabelDisplay="on"
                                />
                                <Typography gutterBottom>Photography</Typography>
                                <Slider
                                    disabled
                                    defaultValue={57}
                                    aria-label="Disabled slider"
                                    valueLabelDisplay="on"
                                />
                            </Box>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container">
                    <div className="content__card" data-aos="zoom-in" data-aos-offset="300">
                        <p>
                            <NavLink className="link-selected"to={{ pathname: '/about-us' }} target={"_blank"} exact>Elite Globe Solutions </NavLink>
                            is a first-class digital service providing company ensuring alerting results, significance in the sphere of marketing. Currently, no entrepreneur or business owner escapes the fact of involving virtual clients and purchasers in the
                            <NavLink className="link-selected" to={{ pathname: '/social-media-management' }} target={"_blank"} exact> digital media </NavLink>
                            where the societal pulse ticks so rapidly that it is almost impossible to follow the daily changes.
                            <br />
                            <br />
                            We do not simply offer services outwardly so modern, we show the way to prosperity, wealth by involving “Gods” of
                            <NavLink className="link-selected" to={{ pathname: '/cold-calls' }} target={"_blank"} exact> digital services.</NavLink>
                            Having Transformation happens rapidly by implementing a step-by-step growth policy- block on block, “stone on stone”.
                            <br />
                            <br />
                            Opening new hazards Elite Globe Solutions attains a noteworthy reputation benefiting from your success which directly means that your profitability is our earning! Multi-access computing seems to be so time-consuming yet indispensable.
                            <br />
                            <br />
                            We help to unlock new possibilities by introducing the whole bulk of digital services in brightest lights, from the most efficient corner. Being out of competition, preparing a fruitful soil for advancing ultimatums. Tailored transformations broke down any practicable barriers accelerating far-fetching upshots that do not keep you waiting. Investment in promotion has never been so warranted. We drive productive ideas to gain predictable, even exceeding expectations outcomes not the opposite.
                            <br />
                            <br />
                            Modernizing, digitalizing, shaping policies operating globally not regionally (not excluding the analysis related to vulnerable groups preconditioned by geographic locations). Gain popularity in commercial sector elevating insights, uncovering innovations, increasing rates, exploiting brands!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseUs;