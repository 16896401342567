import { Helmet } from "react-helmet";
import './Packages.scss';
import { Link } from 'react-router-dom';
import HighArticle from '../../highArticle/HighArticle';
import { BiDollar } from 'react-icons/bi';
import Silver from '../../../assets/img/packages-silver.png';
import Gold from '../../../assets/img/packages-gold.png';
import Platinium from '../../../assets/img/packages-platinium.png';

function Packages() {
    const data = [
        {
            id: 1,
            name: "SILVER",
            img: Silver,
            price: "Contact Sales",
            list1: "Facebook, Instagram, +1 Social Pages creation",
            list2: "Daily posts",
            list3: "Social Sharings",
            list4: "Group Sharings",
            list5: "More Local Followers",
            list6: "$50 pay Ads",
        },
        {
            id: 2,
            name: "GOLDEN (BY CITY)",
            img: Gold,
            price: "Contact Sales",
            list1: "Branding",
            list2: "Blog Creation",
            list3: "Full On Page SEO",
            list4: "8 Social Pages Creation",
            list5: "40+Local Listing Creation",
            list6: "Backlink Building",
        },
        {
            id: 3,
            name: "PLATINUM (NATIONWIDE)",
            img: Platinium,
            price: "Contact Sales",
            list1: "Gold Package Included",
            list2: "White Hat Off Page SEO",
            list3: "Backlink Building",
            list4: "Articles Sharing on High DA platforms",
            list5: "30+ Local Listings Creation by State",
            list6: "12+ Social Pages Creation",
        }
    ]

    return (
        <div>
            <Helmet>
                <title>Gold Package Included | Digital Marketing Experts</title>
                <link rel="canonical" href="http://eliteglobesolutions.com/packages" />
                <meta name="description" content="Digital Marketing Experts" />
            </Helmet>
            <HighArticle name='Packages' />

            <div className="packages">
                <div className="container">
                    <div className="packages__title" data-aos="zoom-in" data-aos-offset="300">
                        <p>VARIES ON MONTHLY BASIS</p>
                        <h1 className="sec_title3">OUR PACKAGES AND PRICING<br />FOR YOU!</h1>
                    </div>

                    <div className="packages__box">
                        {data.map(slide => (
                            <div key={slide.id} className="packages__card" data-aos="zoom-in" data-aos-offset="300">
                                <h2>{slide.name}</h2>
                                <div className="packages__box-img">
                                    <img src={slide.img} alt="img" />
                                </div>
                                <div className="packages__card-price">
                                    <BiDollar className="packages__card-symbol" />
                                    <p>{slide.price}</p>
                                    <span>/mo</span>
                                </div>
                                <Link className="btn-redirect2" to='/contact-us' exact='true'>Get Started</Link>
                                <ul className="packages__card-list">
                                    <li>{slide.list1}</li>
                                    <li>{slide.list2}</li>
                                    <li>{slide.list3}</li>
                                    <li>{slide.list4}</li>
                                    <li>{slide.list5}</li>
                                    <li>{slide.list6}</li>
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Packages;
