import { Helmet } from "react-helmet";
import Main from './Main';
import SliderMarketing from './SliderMarketing';
import CompanySection from './CompanySection';
import Description from './Description';
import SliderPartners from './SliderPartners';
import Testimonials from './Testimonials';
import HomeBlog from './HomeBlog';

function Home() {
    return (
        <>
            <Helmet>
                <title>Best Mobile Digital Marketing Agency Burbank</title>
                <link rel="canonical" href="http://eliteglobesolutions.com" />
                <meta name="description" content="Full Service Digital Marketing Agency LA. Building your online presence is about more than just being present." />
            </Helmet>
            <Main />
            <SliderMarketing />
            <CompanySection />
            <Description />
            <SliderPartners />
            <Testimonials />
            <HomeBlog />
        </>
    )
}

export default Home;
