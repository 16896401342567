import idGenerator from './../components/idGenerator'
import BlogPic1 from '../assets/img/BlogPic4.jpg';
import BlogPic2 from '../assets/img/BlogPic2.jpg';
import BlogPic3 from '../assets/img/BlogPic3.jpg';
import BlogPic4 from '../assets/img/BlogPic1.jpg';

const initialState = [
    
];

const blogReducer = (state = initialState, action) => {
    switch(action.type){
        default:
            return state
    }
}

export default blogReducer