



const initialState = {
    data2:[]
}

export const portfolioReducer = (state = initialState, action) => {
    switch(action.type){
        default:
            return state
    }

}