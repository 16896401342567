import './Admin.css'
import { useContext, useRef, useState } from 'react'
import { Context } from '../../index'
import { setDoc, doc,deleteDoc, updateDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import Edit from './Edit'




const Admin = () => {

    const { db, storage, store } = useContext(Context)
    const portfolio = store.getState().portfolio.data2
    const [divId,setDivId] = useState('')
    const [title, setTitle] = useState('')
    const [mainTitle, setMainTitle] = useState('')
    const [url,setUrl] = useState('')
    const [paragraph, setParagraph] = useState('')
    const [file, setFile] = useState(null)
    const [file2, setFile2] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [imageUrl2, setImageUrl2] = useState(null)
    const [category, setCategory] = useState('web')
    const [uploaded,setUploaded] = useState(null)
    const date = Date.now()
    const [loginData,setLoginData] = useState(false)
    const [loginInput,setLoginInput] = useState('')
    const [editBox,setEditBox] = useState(false)

    const loginToAdmin = () => {
        if(loginInput === '1a@3g7jfasqA'){
            setLoginData(true)
        }
    }

    

    const addPost = () => {
        if (!file) return
        const storageRef = ref(storage, '/images/' + file.name)
        const storageRef2 = ref(storage, '/images/' + file2.name)
        uploadBytes(storageRef, file).then(response => {
            console.log('file1 uploaded')
        })
        if (file) {
            getDownloadURL(storageRef,file.name)
                .then(url => {
                    setImageUrl(url)
                    console.log('file 1 url -' + imageUrl)
                })
        }
        uploadBytes(storageRef2, file2).then(response => {
            console.log('file2 uploaded')
        })
        if (file2) {
            getDownloadURL(storageRef2,file2.name)
                .then(url => {
                    setImageUrl2(url)
                    console.log('file 2 url -' + imageUrl2)
                })
        }


        setTimeout(() => {
            if(imageUrl !== null && imageUrl2 !== null){
                setDoc(doc(db, 'messages', date + 'id'), {
                    id: date + 'id',
                    name: title,
                    paragraph: paragraph,
                    type: category,
                    img: imageUrl,
                    image2: imageUrl2,
                    path: url
                })
                setUploaded(true)
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }else{
                setUploaded(false)
            }
        }, 2000)




    }




    return (
        <div>
            {loginData === true
            ?
            <div className="main">
            <div className='admin_inputs'>
                <div className='admin_input1'>
                    <input type={"text"} placeholder="URL" value={url} onChange={(e) => setUrl(e.target.value)} />
                </div>
                <div className='admin_input1'>
                    <input type={"text"} placeholder="Main Title" value={mainTitle} onChange={(e) => setMainTitle(e.target.value)} />
                </div>
                <div className='admin_input1'>
                    <input type={"text"} placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className='admin_textarea'>
                    <textarea rows={'19'} placeholder="Description" value={paragraph} onChange={(e) => setParagraph(e.target.value)}></textarea>
                </div>
                <div className="centered_div">
                    <div className='flex_direction_class'>
                            <div className='admin_file_input'>
                                <label>Main picture</label>
                                <input type='file' onChange={(e) => setFile(e.target.files[0])} />
                            </div>
                            <div className='admin_file_input'>
                                <label className='second_photo_label'>Article picture</label>
                                <input type='file' onChange={(e) => setFile2(e.target.files[0])} />
                            </div>
                            <div className='select_category'>
                                <select className='' value={category} onChange={e => setCategory(e.target.value)}>
                                    <option value={'web'} onChange={e => setCategory(e.target)}>Web</option>
                                    <option value={'smm'} onChange={e => setCategory(e.target)}>SMM</option>
                                    <option value={'seo'} onChange={e => setCategory(e.target.value)}>SEO</option>
                                    <option value={'game'} onChange={e => setCategory(e.target.value)}>Game development</option>
                                </select>
                            </div>
                            <div className='create_admin_post'>
                                <button onClick={addPost}>Create Post</button>
                            </div>
                            {uploaded  
                            ?
                                <div>
                                    <span className='succses_upload'>Succsesfully uploaded</span>
                                </div>
                            :
                                <div>
                                    <span className='reject_upload'>Try again dont uploaded</span>
                                </div>
                            }
                        <div className='bottom_content'>
                            {portfolio.map(item => (
                                <>
                                    <div key={item.id} id={item.id} onClick={(e) => setDivId(e.currentTarget.id)} >
                                        
                                        <h3>{item.name}</h3>
                                        <img src={item.img} />
                                        <p>{item.paragraph}</p>
                                        <button className='delete_button' onClick={() => {
                                            setTimeout(() => {
                                                deleteDoc(doc(db,'messages', divId))
                                            }, 2000)
                                        }}>Delete</button>
                                        <button className='edit_button' onClick={() => setEditBox(!editBox)}>Edit</button>
                                        {editBox === true && item.id == divId 
                                        ?
                                            <Edit data={portfolio} id={divId} />
                                        :
                                            null
                                        }
                                        {/* <button onClick={() => {
                                            setTimeout(() => {
                                                updateDoc(doc(db,'messages', divId, {

                                                }))
                                            })
                                        }}>
                                            Edit
                                        </button> */}
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
            :
            <div className='login_block'>
                <div className='login_block_flex'>
                    <h1>Login to admin</h1>
                    <input value={loginInput} type='password' onChange={(e) => setLoginInput(e.target.value)} />
                    <button onClick={loginToAdmin} className='login_button'>Login</button>
                </div>
            </div>
            }
            

        </div>
    )
}



export default Admin