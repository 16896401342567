import './notFound.scss';
import notfound from '../../../assets/img/notfound.png';

const notFound = () => {
    return (
        <div className="wrapper">
            <img src={notfound} alt="404-page" />
        </div >
    )
}

export default notFound;
