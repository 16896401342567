import './AdminBlog.css'
import { useContext, useRef, useState } from 'react'
import { Context } from '../../index'
import { setDoc, doc, deleteDoc, updateDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import idGenerator from '../idGenerator'
import { MdTimeToLeave } from 'react-icons/md'


const EditBlog = (props) => {

    const { db, storage, store } = useContext(Context)
    const [dateNow,setDateNow] = useState(props.data.filter(item => item.id === props.id).map(item => item.date))
    const [dateNowChanged,setDateNowChanged] = useState('')
    const [link,setLink] = useState(props.data.filter(item => item.id === props.id).map(item => item.link))
    const [linkChanged,setLinkChanged] = useState(false)
    const [title, setTitle] = useState(props.data.filter(item => item.id === props.id).map(item => item.title))
    const [titleChanged,setTitleChanged] = useState(false)
    const [name, setName] = useState(props.data.filter(item => item.id === props.id).map(item => item.name))
    const [nameChanged,setNameChanged] = useState(false)
    const [paragraph1, setParagraph1] = useState(props.data.filter(item => item.id === props.id).map(item => item.mainText))
    const [changedParagraph1,setChangedParagraph1] = useState(false)
    const [paragraph2, setParagraph2] = useState(props.data.filter(item => item.id === props.id).map(item => item.text1))
    const [changedParagraph2,setChangedParagraph2] = useState(false)
    const [paragraph3, setParagraph3] = useState(props.data.filter(item => item.id === props.id).map(item => item.text2))
    const [changedParagraph3,setChangedParagraph3] = useState(false)
    const [paragraph4, setParagraph4] = useState(props.data.filter(item => item.id === props.id).map(item => item.text3))
    const [changedParagraph4,setChangedParagraph4] = useState(false)
    const [paragraph5, setParagraph5] = useState(props.data.filter(item => item.id === props.id).map(item => item.text4))
    const [changedParagraph5,setChangedParagraph5] = useState(false)
    const [paragraph6, setParagraph6] = useState(props.data.filter(item => item.id === props.id).map(item => item.text5))
    const [changedParagraph6,setChangedParagraph6] = useState(false)

    const [about, setAbout] = useState(props.data.filter(item => item.id === props.id).map(item => item.about))
    const [aboutChanged, setAboutChanged] = useState(false)
    const [tag1,setTag1] = useState(props.data.filter(item => item.id === props.id).map(item => item.tag1))
    const [changedTag1,setChangedTag1] = useState('')
    const [tag2,setTag2] = useState(props.data.filter(item => item.id === props.id).map(item => item.tag2))
    const [changedTag2,setChangedTag2] = useState('')
    const [tag3,setTag3] = useState(props.data.filter(item => item.id === props.id).map(item => item.tag3))
    const [changedTag3,setChangedTag3] = useState('')
    const [tag4,setTag4] = useState(props.data.filter(item => item.id === props.id).map(item => item.tag4))
    const [changedTag4,setChangedTag4] = useState('')
    const [tag5,setTag5] = useState(props.data.filter(item => item.id === props.id).map(item => item.tag5))
    const [changedTag5,setChangedTag5] = useState('')
    const [file, setFile] = useState(null)
    const [fileChanged, setFileChanged] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const [imageUrlChanged, setImageUrlChanged] = useState(null)
    const [uploaded, setUploaded] = useState(null)
    const [loginInput,setLoginInput] = useState('')
    const [loginData,setLoginData] = useState(false)
    const date = Date.now()

    const loginAdmin = () => {
        if(loginInput === "1a@3g7jfasqA"){
            setLoginData(true)
        }
    }

    const addPost = () => {
        if (!file) return
        const storageRef = ref(storage, '/images/' + file.name)
        uploadBytes(storageRef, file).then(response => {
            console.log('file1 uploaded')
        })
        if (file) {
            getDownloadURL(storageRef, file.name)
                .then(url => {
                    setImageUrl(url)
                    console.log('file 1 url -' + imageUrl)
                })
        }

        if(imageUrl !== null){
            updateDoc(doc(db,'messages', props.id), {
                img: imageUrl,
            })
        }
        if(title !== '' && titleChanged === true){
            updateDoc(doc(db,'messages', props.id), {
                title: title,
            })
        }
        if(name !== '' && nameChanged === true){
            updateDoc(doc(db,'messages', props.id), {
                name: name,
            })
        }
        if(dateNow !== '' && dateNowChanged === true){
            updateDoc(doc(db,'messages', props.id), {
                date: dateNow,
            })
        }
        if(paragraph1 !== '' && changedParagraph1 === true){
            updateDoc(doc(db,'messages', props.id), {
                mainText: paragraph1,
            })
        }
        if(paragraph2 !== '' && changedParagraph2 === true){
            updateDoc(doc(db,'messages', props.id), {
                text1: paragraph2,
            })
        }
        if(paragraph3 !== '' && changedParagraph3 === true){
            updateDoc(doc(db,'messages', props.id), {
                text2: paragraph3,
            })
        }
        if(paragraph4 !== '' && changedParagraph4 === true){
            updateDoc(doc(db,'messages', props.id), {
                text3: paragraph4,
            })
        }
        if(paragraph5 !== '' && changedParagraph5 === true){
            updateDoc(doc(db,'messages', props.id), {
                text4: paragraph5,
            })
        }

        if(about !== '' && aboutChanged === true){
            updateDoc(doc(db,'messages', props.id), {
                about: about,
            })
        }
        if(tag1 !== '' && changedTag1 === true){
            updateDoc(doc(db,'messages', props.id), {
                tag1: tag1,
            })
        }
        if(tag2 !== '' && changedTag2 === true){
            updateDoc(doc(db,'messages', props.id), {
                tag2: tag2,
            })
        }
        if(tag3 !== '' && changedTag3 === true){
            updateDoc(doc(db,'messages', props.id), {
                tag3: tag3,
            })
        }
        if(tag4 !== '' && changedTag4 === true){
            updateDoc(doc(db,'messages', props.id), {
                tag4: tag4,
            })
        }
        if(tag5 !== '' && changedTag5 === true){
            updateDoc(doc(db,'messages', props.id), {
                tag5: tag5,
            })
        }




    }




    return (
        <div>

            <div className="main">
            <div className='admin_inputs'>
                <div className='admin_inputs_column'>
                    <div className='admin_input1'>
                        <input type={"text"} placeholder="URL" value={link} onChange={(e) => {
                            setLink(e.target.value)
                            setLinkChanged(true)
                        }} />
                    </div>
                    <div className='admin_input1'>
                        <input type={"text"} placeholder="Title" value={title} onChange={(e) => {
                            setTitle(e.target.value)
                            setTitleChanged(true)
                        }} />
                    </div>
                    <div className='admin_input1'>
                        <input type={"text"} placeholder="Name" value={name} onChange={(e) => {
                            setName(e.target.value)
                            setNameChanged(true)
                        }} />
                    </div>
                    <div className='admin_input1'>
                        <input type={"text"} placeholder="Date" value={dateNow} onChange={(e) => {
                            setDateNow(e.target.value)
                            setDateNowChanged(true)
                        }} />
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} placeholder="Main text" value={paragraph1} onChange={(e) => {
                            setParagraph1(e.target.value)
                            setChangedParagraph1(true)
                        }}></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} value={paragraph2} placeholder={"Text 1"} onChange={(e) => {
                            setParagraph2(e.target.value)
                            setChangedParagraph2(true)
                        }}></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} value={paragraph3} placeholder={"Text 2"} onChange={(e) => {
                            setParagraph3(e.target.value)
                            setChangedParagraph3(true)
                        }}></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} value={paragraph4} placeholder={"Text 3"} onChange={(e) => {
                            setParagraph4(e.target.value)
                            setChangedParagraph4(true)
                        }}></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} value={paragraph5} placeholder={"Text 4"} onChange={(e) => {
                            setParagraph5(e.target.value)
                            setChangedParagraph5(true)
                        }}></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} value={paragraph6} placeholder={"Text 5"} onChange={(e) => {
                            setParagraph6(e.target.value)
                            setChangedParagraph6(true)
                        }}></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} value={about} placeholder={"Quote (Centered text 6)"} onChange={(e) => {
                            setAbout(e.target.value)
                            setAboutChanged(true)
                        }}></textarea>
                    </div>
                    <div className='tags'>
                        <div className='tag_input'>
                            <input type={'text'} value={tag1} placeholder='Tag 1' onChange={e => {
                                setTag1(e.target.value)
                                setChangedTag1(e.target.value)
                            }}/>
                        </div>
                        <div className='tag_input'>
                            <input type={'text'} value={tag2} placeholder='Tag 2' onChange={e => {
                                setTag2(e.target.value)
                                setChangedTag2(e.target.value)
                            }}/>
                        </div>
                        <div className='tag_input'>
                            <input type={'text'} value={tag3} placeholder='Tag 3' onChange={e => {
                                setTag3(e.target.value)
                                setChangedTag3(e.target.value)
                            }}/>
                        </div>
                        <div className='tag_input'>
                            <input type={'text'} value={tag4} placeholder='Tag 4' onChange={e => {
                                setTag4(e.target.value)
                                setChangedTag4(e.target.value)
                            }}/>
                        </div>
                        <div className='tag_input'>
                            <input type={'text'} value={tag5} placeholder='Tag 5' onChange={e => {
                                setTag5(e.target.value)
                                setChangedTag5(e.target.value)
                            }}/>
                        </div>
                    </div>
                    <div className="centered_div">
                        <div className='flex_direction_class'>
                                <div className='admin_file_input'>
                                    <input type='file' onChange={(e) => {
                                        setFile(e.target.files[0])
                                        setFileChanged(true)
                                    }} />
                                </div>
                                <div className='create_admin_post'>
                                    <button onClick={addPost}>Save Edit</button>
                                </div>
                                {uploaded
                                    ?
                                    <div>
                                        <span className='succses_upload'>Succsesfully uploaded</span>
                                    </div>
                                    :
                                    <div>
                                        <span className='reject_upload'>Try again dont uploaded</span>
                                    </div>
                                }
                        </div>
                    </div>
                </div>
                

            </div>
        </div>  

        </div>
    )
}


export default EditBlog