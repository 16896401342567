import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore' 
import {getStorage} from 'firebase/storage'
import store from './redux/store';


const firebaseConfig = {
  apiKey: "AIzaSyCXGZmFY3G3ihNOAsqX3zHNKUsvKESJMRQ",
  authDomain: "admin-panel-63321.firebaseapp.com",
  projectId: "admin-panel-63321",
  storageBucket: "admin-panel-63321.appspot.com",
  messagingSenderId: "190730244129",
  appId: "1:190730244129:web:a5978d2acd375f198c1360",
  measurementId: "G-GL7E9CRXXY"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app)
const db = getFirestore(app)
export const Context = createContext(null)

ReactDOM.render(
  <React.StrictMode>
    <Context.Provider value={{
      db,
      storage,
      store
    }}>
      <App />
    </Context.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
