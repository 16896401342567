import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import './Header.scss';
import Logo from '../../assets/img/logo.png'
import { NavLink, Link } from 'react-router-dom';
import { RiFacebookFill } from 'react-icons/ri';
import { RiInstagramLine } from 'react-icons/ri';
import { RiTwitterFill } from 'react-icons/ri';
import { RiLinkedinBoxFill } from 'react-icons/ri';
import { FiChevronDown } from 'react-icons/fi';

function Header() {
    const { pathname } = useLocation();

    //menu open close
    let [burgerI, setBurger] = useState('close');

    let [openBurger, setOpenBurger] = useState(false);

    let handleClick = () => {
        setBurger(burgerI === 'open' ? 'close' : 'open');
        setOpenBurger(!openBurger)
    }
    useEffect(() => {
        setOpenBurger(false);
        setBurger('close')

    }, [pathname]);

    return (
        <header className="header">
            <div className="container">
                <nav className="header__nav">
                    <NavLink to='/' exact>
                        <img className="header__logo" src={Logo} alt="Logo" />
                    </NavLink>

                    <ul className={`header__list ${openBurger ? 'header__list-active' : ''}`}>
                        <li>
                            <NavLink activeStyle={{
                                color: "#DBDEE3",
                                background: "#272B47",
                                borderRadius: "7%",
                                textDecoration: "none"
                            }} className="header__link" to='/' exact>Home</NavLink>

                        </li>
                        <li className="dropdown__li">
                            <div
                                className="header__link with__icon">Company<FiChevronDown className="header__link-down" />
                            </div>

                            {/* Company Dropdown */}
                            <ul className="company__dropdown">
                                <li>
                                    <NavLink activeStyle={{
                                        textDecoration: "underline"
                                    }} className="company__dropdown-link" to={{ pathname: '/about-us'}} exact>About Us</NavLink>
                                </li>
                                <li>
                                    <NavLink activeStyle={{
                                        textDecoration: "underline"
                                    }} className="company__dropdown-link" to={{ pathname: '/choose-us'}} exact>Why Choose Us</NavLink>
                                </li>
                                <li>
                                    <NavLink activeStyle={{
                                        textDecoration: "underline"
                                    }} className="company__dropdown-link" to={{ pathname: '/faqs'}} exact>FAQs</NavLink>
                                </li>
                                <li>
                                    <NavLink activeStyle={{
                                        textDecoration: "underline"
                                    }} className="company__dropdown-link" to={{ pathname: '/careers'}} exact>Careers</NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className="dropdown__li">
                            <div
                                className="header__link with__icon">Services<FiChevronDown className="header__link-down" />
                            </div>

                            {/* Services Dropdown */}
                            <ul className="services__dropdown">
                                <div>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link" to={{ pathname: '/cold-calls'}} exact>Cold calls</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link" to={{ pathname: '/ecommerce-seo'}} exact>E-commerce SEO</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link" to={{ pathname: '/email-marketing'}} exact>E-mail Marketing</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link" to={{ pathname: '/ppc'}} exact>PPC</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link" to={{ pathname: '/seo'}} exact>SEO</NavLink>
                                    </li>
                                </div>

                                <div>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link" to={{ pathname: '/shopify'}} exact>Shopify</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link" to={{ pathname: '/small-business'}} exact>Small Business</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link" to={{ pathname: '/social-media-management'}} exact>Social Media Management</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link" to={{ pathname: '/web-design-development'}} exact>Website Design & Development</NavLink>
                                    </li> 
                                </div>

                                <div><p className="services__dropdown2-p">LOCATION/AREAS</p>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link" to={{ pathname: '/burbank-digital-marketing'}} exact>Burbank</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link"  to={{ pathname: '/glendale-telemarketing'}} exact>Glendale</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link"  to={{ pathname: '/hollywood-digital-marketing'}} exact>Hollywood</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link"  to={{ pathname: '/northhollywood-mobile-marketing'}} exact>North Hollywood</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link"  to={{ pathname: '/pasadena-mobile-game-development'}} exact>Pasadena</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            textDecoration: "underline"
                                        }} className="services__dropdown-link"  to={{ pathname: '/westhollywood-telehealth-marketing'}} exact>West Hollywood</NavLink>
                                    </li>
                                </div>
                            </ul>
                        </li>


                        <li>
                            <NavLink activeStyle={{
                                color: "#DBDEE3",
                                background: "#272B47",
                                borderRadius: "7%",
                                textDecoration: "none"
                            }} className="header__link" to={{ pathname: '/packages'}} exact>Packages</NavLink></li>
                        <li>
                            <NavLink activeStyle={{
                                color: "#DBDEE3",
                                background: "#272B47",
                                borderRadius: "7%",
                                textDecoration: "none"
                            }} className="header__link" to={{ pathname: '/portfolio'}} exact>Portfolio</NavLink></li>
                        <li>
                            <NavLink activeStyle={{
                                color: "#DBDEE3",
                                background: "#272B47",
                                borderRadius: "7%",
                                textDecoration: "none"
                            }} className="header__link" to={{ pathname: '/blog'}} exact>Blog</NavLink></li>
                        <li>
                            <NavLink activeStyle={{
                                color: "#DBDEE3",
                                background: "#272B47",
                                borderRadius: "7%",
                                textDecoration: "none"
                            }} className="header__link" to={{ pathname: '/contact-us'}} exact>Contacts</NavLink></li>
                    </ul>

                    <div className="header__social">
                        <Link className="header__social-icon" to={{ pathname: "https://www.facebook.com/eliteglobesolutions/ " }} name="Facebook"  target={"_blank"}><RiFacebookFill /></Link>
                        <Link className="header__social-icon" to={{ pathname: "https://www.instagram.com/eliteglobesolutions/ " }} name="Instagram" target={"_blank"}><RiInstagramLine /></Link>
                        <Link className="header__social-icon" to={{ pathname: "https://twitter.com/elite_globe " }} name="Twitter" target={"_blank"}><RiTwitterFill /></Link>
                        <Link className="header__social-icon" to={{ pathname: "https://www.linkedin.com/company/elite-globe-web-development-digital-marketing/" }} name="Linkedin" target={"_blank"}><RiLinkedinBoxFill /></Link>
                    </div>

                    <div className="header__burger" role="button" onClick={handleClick}>
                        <i className={burgerI}></i>
                        <i className={burgerI}></i>
                        <i className={burgerI}></i>
                    </div>

                </nav>
            </div>
        </header >
    )
}

export default Header;


