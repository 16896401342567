import { Helmet } from "react-helmet";
import HighArticle from '../../highArticle/HighArticle';
import './Blog.scss';
import { Link } from 'react-router-dom';
import { BsCalendar2Week } from 'react-icons/bs';
import SidebarPic from '../../../assets/img/blog-sidebar.jpg'
import {Context} from '../../../index'
import { useContext } from "react";


function Blog() {
    const {store,db} = useContext(Context)
    const data = store.getState().blog

    return (
        <div>
            <Helmet>
                <title>Best Digital Visibility Concepts | Burbank | Blog</title>
                <link rel="canonical" href="http://eliteglobesolutions.com/blog" />
                <meta name="description" content="Best Digital Visibility Concepts" />
            </Helmet>
            <HighArticle name='Blog' />

            <div className="blog">
                <div className="container">
                    <div className="blog__main">
                        <div className="blog__card" data-aos="zoom-in" data-aos-offset="300">
                            {data.map(el => (
                                <div key={el.id} className="blog__post">
                                    <div className="blog__post-img">
                                        <img src={el.img} alt="Blog-Img" />
                                    </div>
                                    <h2>{el.name}</h2>
                                    <Link to={{
                                        pathname: el.link,
                                        state: {
                                            id: el.id,
                                            title: el.title
                                        }
                                    }}
                                        className="blog__post-title">{el.title}
                                    </Link>
                                    <br />
                                    <span><BsCalendar2Week className="blog__post-date" /> {el.date}</span>
                                    <p>{el.mainText}</p>
                                    <Link to={{
                                        pathname: el.link,
                                        state: {
                                            id: el.id,
                                            title: el.title
                                        }
                                    }}
                                        className="btn-redirect">Read more</Link>
                                </div>
                            ))}
                        </div>

                        <div className="blog__sidebar" data-aos="zoom-in" data-aos-offset="300">
                            <h4>Reacent Posts</h4>
                            <ul>
                                {data.map(el => (
                                    <li key={el.id}><Link to={{
                                        pathname: el.link,
                                        state: {
                                            id: el.id,
                                            title: el.title
                                        }
                                    }} className="blog__sidebar-link">{el.title}</Link></li>
                                ))}
                            </ul>
                            <div className="blog__sidebar-img">
                                <img src={SidebarPic} alt="" />
                            </div>
                            <h4>Popular Tags</h4>
                            <div className="blog__sidebar-tags">
                                <button className="blog__sidebar-btn">Business</button>
                                <button className="blog__sidebar-btn">IT Security</button>
                                <button className="blog__sidebar-btn">IT Services</button>
                                <button className="blog__sidebar-btn">Marketing</button>
                                <button className="blog__sidebar-btn">Smm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog;
