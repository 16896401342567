import { useRef } from 'react';
import emailjs from 'emailjs-com';
import './BlogComment.scss';

function BlogComment() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_uwjtloj', 'template_c54jzda', e.target, 'user_gmLXHO5qvoc7mD6ohzjQa')
            .then(() => {
                alert("Your message is send!");
            }, (error) => {
                alert(error.text);
            });
        e.target.reset()
    };

    return (
        <form className="blogCom__form" ref={form} onSubmit={sendEmail} >
            <div className="blogCom__form-between">
                <input type="text" name="name" placeholder="Your Name *" required />
                <input type="email" name="email" placeholder="Your Email *" required />
            </div>
            <textarea name="message" placeholder="Your Comment." required />
            <button className="btn-redirect btn-center btn-norm" type="submit">Submit</button>
        </form>
    )
}

export default BlogComment;
