import './AdminBlog.css'
import { useContext, useRef, useState } from 'react'
import { Context } from '../../index'
import { setDoc, doc, deleteDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import idGenerator from '../idGenerator'
import exapmleImage from '../../assets/img/example.png'
import Edit from './Edit'
import EditBlog from './EditBlog'

const AdminBlog = () => {

    const { db, storage, store } = useContext(Context)
    const blog = store.getState().blog
    const [divId,setDivId] = useState('')
    const [link,setLink] = useState('')
    const [dateNow,setDateNow] = useState('')
    const [title, setTitle] = useState('')
    const [name, setName] = useState('')
    const [paragraph1, setParagraph1] = useState('')
    const [paragraph2, setParagraph2] = useState('')
    const [paragraph3, setParagraph3] = useState('')
    const [paragraph4, setParagraph4] = useState('')
    const [paragraph5, setParagraph5] = useState('')
    const [paragraph6, setParagraph6] = useState('')
    const [about, setAbout] = useState('')
    const [tag1,setTag1] = useState('')
    const [tag2,setTag2] = useState('')
    const [tag3,setTag3] = useState('')
    const [tag4,setTag4] = useState('')
    const [tag5,setTag5] = useState('')
    const [file, setFile] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [category, setCategory] = useState('web')
    const [uploaded, setUploaded] = useState(null)
    const [loginInput,setLoginInput] = useState('')
    const [loginData,setLoginData] = useState(false)
    const [editBox,setEditBox] = useState(false)
    const date = Date.now()

    const loginAdmin = () => {
        if(loginInput === "1a@3g7jfasqA"){
            setLoginData(true)
        }
    }

    const addPost = () => {
        if (!file) return
        const storageRef = ref(storage, '/images/' + file.name)
        uploadBytes(storageRef, file).then(response => {
            console.log('file1 uploaded')
        })
        if (file) {
            getDownloadURL(storageRef, file.name)
                .then(url => {
                    setImageUrl(url)
                    console.log('file 1 url -' + imageUrl)
                })
        }

        setTimeout(() => {
            if (imageUrl !== null) {
                setDoc(doc(db, 'blog', date + 'id'), {
                    id: date + 'id',
                    key: idGenerator(),
                        img: imageUrl,
                        name: name,
                        title: title,
                        link: '/blog/' + link,
                        date: dateNow,
                        mainText: paragraph1,
                        text1: paragraph2,
                        text2: paragraph3,
                        about: about,
                        text3: paragraph4,
                        text4: paragraph5,
                        text5: paragraph6,
                        tag1: tag1,
                        tag2: tag2,
                        tag3: tag3,
                        tag4: tag4,
                        tag5: tag5,

                })
                setUploaded(true)
            } else {
                setUploaded(false)
            }
        }, 2000)




    }




    return (
        <div>
            {loginData 
            ?
            <div className="main">
            <div className='admin_inputs'>
                <img src={exapmleImage}  className='example__image'/>
                <div className='admin_inputs_column'>
                    <div className='admin_input1'>
                        <input type={"text"} placeholder="URL" value={link} onChange={(e) => setLink(e.target.value)} />
                    </div>
                    <div className='admin_input1'>
                        <input type={"text"} placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className='admin_input1'>
                        <input type={"text"} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className='admin_input1'>
                        <input type={"text"} placeholder="Date" value={dateNow} onChange={(e) => setDateNow(e.target.value)} />
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} placeholder="Main text" value={paragraph1} onChange={(e) => setParagraph1(e.target.value)}></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} value={paragraph2} placeholder={"Text 1"} onChange={(e) => setParagraph2(e.target.value)}></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} value={paragraph3} placeholder={"Text 2"} onChange={(e) => setParagraph3(e.target.value)}></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} value={paragraph4} placeholder={"Text 3"} onChange={(e) => setParagraph4(e.target.value)}></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} value={paragraph5} placeholder={"Text 4"} onChange={(e) => setParagraph5(e.target.value)}></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} value={paragraph6} placeholder={"Text 5"} onChange={(e) => setParagraph6(e.target.value)}></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} value={about} placeholder={"Quote (Centered text 6)"} onChange={(e) => setAbout(e.target.value)}></textarea>
                    </div>
                    <div className='tags'>
                        <div className='tag_input'>
                            <input type={'text'} value={tag1} placeholder='Tag 1' onChange={e => setTag1(e.target.value)}/>
                        </div>
                        <div className='tag_input'>
                            <input type={'text'} value={tag2} placeholder='Tag 2' onChange={e => setTag2(e.target.value)}/>
                        </div>
                        <div className='tag_input'>
                            <input type={'text'} value={tag3} placeholder='Tag 3' onChange={e => setTag3(e.target.value)}/>
                        </div>
                        <div className='tag_input'>
                            <input type={'text'} value={tag4} placeholder='Tag 4' onChange={e => setTag4(e.target.value)}/>
                        </div>
                        <div className='tag_input'>
                            <input type={'text'} value={tag5} placeholder='Tag 5' onChange={e => setTag5(e.target.value)}/>
                        </div>
                    </div>
                    <div className="centered_div">
                        <div className='flex_direction_class'>
                                <div className='admin_file_input'>
                                    <input type='file' onChange={(e) => setFile(e.target.files[0])} />
                                </div>
                                <div className='create_admin_post'>
                                    <button onClick={addPost}>Create Post</button>
                                </div>
                                {uploaded
                                    ?
                                    <div>
                                        <span className='succses_upload'>Succsesfully uploaded</span>
                                    </div>
                                    :
                                    <div>
                                        <span className='reject_upload'>Try again dont uploaded</span>
                                    </div>
                                }
                            <div>
                                {blog.map(item => (
                                    <>
                                        <div key={item.id} id={item.id} onClick={(e) => setDivId(e.currentTarget.id)}>
                                            <h3 className='color_white'>{item.title}</h3>
                                            <img src={item.img} />
                                            <button className='delete_button' onClick={(e) => {
                                                setTimeout(() => {
                                                    deleteDoc(doc(db,'blog', divId))
                                                }, 2000)
                                            }}>Delete</button>
                                            <button className='edit_button' onClick={(e) => {
                                                setEditBox(!editBox)
                                            }}>Edit</button>
                                            {editBox === true && item.id === divId 
                                            ? 
                                                <EditBlog data={blog} id={divId} />

                                            :
                                                null
                                            }
                                            
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
            :
            <div className='login_block'>
                <div className='login_block_flex'>
                    <h1>Login to admin</h1>
                    <input value={loginInput} type='password' onChange={(e) => setLoginInput(e.target.value)} />
                    <button onClick={loginAdmin} className='login_button'>Login</button>
                </div>
            </div>
            }
            

        </div>
    )
}


export default AdminBlog